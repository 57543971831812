<template>
  <page-container :isEmbedded="getIsEmbedded">
    <left-container>
      <title-text v-html="companySettings.calculator_configuration.title" />
      <paragraph-text
        v-html="companySettings.calculator_configuration.subtitle"
      />
      <button-container>
        <button-component
          :disabled="false"
          type="primary"
          fontSize="16px"
          dataId="button_home_page_start"
          :text="translate('start_button')"
          @click.native="onStartClick()"
        />
      </button-container>
    </left-container>
    <right-container>
      <main-image
        :src="
          companySettings.calculator_configuration.variation_point_definitions
            .headerImage
            ? companySettings.calculator_configuration
                .variation_point_definitions.headerImage
            : require('@/assets/images/main_default_image.png')
        "
      />
    </right-container>
    <button-container-mobile>
      <button-component
        :disabled="false"
        type="primary"
        fontSize="16px"
        :text="translate('start_button')"
        @click.native="onStartClick()"
      />
    </button-container-mobile>
    <watermark-container v-if="getIsEmbedded">
      <watermark :href="getWatermarkLink" target="_blank">
        &copy; {{ getYear }} - {{ translate('eturnity_ag') }} -
        {{ translate('configurator') }}
      </watermark>
    </watermark-container>
  </page-container>
</template>

<script>
import styled from 'vue-styled-components'
import ButtonComponent from '@/components/reusable-components/ButtonComponent'
import { mapActions, mapGetters } from 'vuex'

const pageAttrs = { isEmbedded: Boolean }
const PageContainer = styled('div', pageAttrs)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${(props) => (props.isEmbedded ? '0' : '50px')};
  grid-gap: 60px;

  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    grid-gap: 40px;
    margin-top: 30px;
    padding: 0 16px 50px;
  }
`

const LeftContainer = styled.div`
  display: grid;
  grid-gap: 40px;
  padding-bottom: 60px;

  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    grid-gap: 20px;
  }
`

const RightContainer = styled.div`
  display: grid;
  align-items: center;
`

const TitleText = styled.div`
  font-size: 52px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black};

  span {
    color: ${(props) => props.theme.colors.secondary};
  }

  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    font-size: 38px;
  }
`

const ParagraphText = styled.div`
  font-size: 24px;
  line-height: 36px;

  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    font-size: 20px;
  }
`

const MainImage = styled.img`
  width: 100%;
  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    width: 70%;
    justify-self: center;
  }
`

const ButtonContainer = styled.div`
  max-width: 160px;

  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    display: none;
  }
`

const ButtonContainerMobile = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    width: 100%;
    display: block;
    align-self: flex-end;
  }
`

const Watermark = styled.a`
  color: ${(props) => props.theme.colors.mediumGray};
  cursor: pointer;
  text-decoration: none;
`

const WatermarkContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  padding: 4px 0;
`

export default {
  name: 'home-page',
  components: {
    PageContainer,
    LeftContainer,
    RightContainer,
    TitleText,
    ParagraphText,
    ButtonComponent,
    MainImage,
    ButtonContainer,
    ButtonContainerMobile,
    WatermarkContainer,
    Watermark
  },
  computed: {
    ...mapActions({
      postStartSession: 'postStartSession'
    }),
    ...mapGetters({
      companySettings: 'getCompanySettings',
      getIsEmbedded: 'getIsEmbedded'
    }),
    getYear() {
      // returns the current year
      return new Date().getFullYear()
    },
    getWatermarkLink() {
      return this.translate('watermark_link')
    }
  },
  methods: {
    onStartClick() {
      this.postStartSession
      let event = {
        name: 'start_button',
        category: 'Input',
        action: 'Clicked',
        description: 'Click on Start button'
      }
      this.analyticsTracking({ event })
    }
  }
}
</script>
