<template>
  <container>
    <progress-container
      :backgroundColor="backgroundColor"
      :maxWidth="maxWidth"
      :minWidth="minWidth"
      class="progress-container"
    >
      <progress-fill
        class="progress-bar"
        :fillProgress="fillProgress"
        :fillColor="fillColor"
      />
    </progress-container>
    <label-text class="progress-text" v-if="stepNumber"
      >{{ labelText ? labelText : translate('question') }}
      {{ stepNumber ? stepNumber : '' }}</label-text
    >
  </container>
</template>

<script>
//To Use:
// <progress-bar
//   :fillColor="#000"
//   :backgroundColor="#888"
//   :minWidth="150px"
//   :maxWidth="100%"
//   :fillProgress="50" //should be a number for percent
//   stepNumber="4"
//   :labelText="translate('step')" // by default, this is 'Question'
// >
import styled from 'vue-styled-components'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    grid-row-gap: 20px;
    grid-template-rows: auto 1fr;
  }
`

const containerProps = {
  backgroundColor: String,
  maxWidth: String,
  minWidth: String
}
const ProgressContainer = styled('div', containerProps)`
  width: 100%;
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'none')};
  height: 6px;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.lightGray};
  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    height: 4px;
  }
`

const fillProps = { fillColor: String, fillProgress: String | Number }
const ProgressFill = styled('div', fillProps)`
  height: 100%;
  width: ${(props) => props.fillProgress + '%'};
  background-color: ${(props) =>
    props.fillColor ? props.fillColor : props.theme.colors.secondary};
`

const labelText = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;

  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    padding-left: 16px;
  }
`

export default {
  name: 'progress-bar',
  components: {
    Container,
    ProgressContainer,
    ProgressFill,
    labelText
  },
  props: {
    fillColor: {
      required: false,
      type: String,
      default: null
    },
    backgroundColor: {
      required: false,
      type: String,
      default: null
    },
    minWidth: {
      required: false,
      type: String,
      default: null
    },
    maxWidth: {
      required: false,
      type: String,
      default: null
    },
    fillProgress: {
      required: false,
      default: 0
    },
    labelText: {
      required: false,
      default: null
    },
    stepNumber: {
      required: false,
      default: null
    }
  }
}
</script>
