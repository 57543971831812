<template>
  <page-container
    v-if="activeQuestion"
    :buttonMargin="showContinueButton"
    :isEmbedded="getIsEmbedded"
  >
    <left-container>
      <progress-bar
        :fillProgress="getPercentComplete"
        :stepNumber="activeQuestion.answered_questions.length + 1"
      />
      <answers-container v-if="$screen.width > 768">
        <selected-answers
          :options="activeQuestion.answered_questions"
          :maxHeight="500"
        />
      </answers-container>
    </left-container>
    <right-container>
      <question-title
        :questionText="activeQuestion.question.title"
        :infoText="getInfoText"
        dataId="text_question_page_active_question_title"
      />
      <questions-container
        v-if="Object.entries(activeQuestion.question.answers).length"
      >
        <question-choices
          dataIdPrefix="option_question_page_active_question_choice"
          :question="activeQuestion.question.answers"
          :selectedAnswer="
            getSelectedAnswer(activeQuestion.question.question_uuid)
          "
          :clearInput="clearInput"
          :isError="
            hasInputError({
              id: activeQuestion.question.question_uuid,
              minValue: activeQuestion.question.answers.number_field
                ? activeQuestion.question.answers.number_field
                    .number_min_allowed
                : null,
              maxValue: activeQuestion.question.answers.number_field
                ? activeQuestion.question.answers.number_field
                    .number_max_allowed
                : null
            })
          "
          :type="activeQuestion.question.question_type"
          @on-answer-selection="
            onAnswerClick({
              value: $event,
              id: activeQuestion.question.question_uuid,
              variable: activeQuestion.question.variable
            })
          "
          @on-answer-input="
            onAnswerInput({
              value: $event,
              id: activeQuestion.question.question_uuid,
              variable: activeQuestion.question.variable
            })
          "
          v-if="!inputOnlyQuestion({ question: activeQuestion.question })"
          @on-enter-click="onSubmitClick()"
        />
        <input-number
          v-else
          :placeholder="translate('enter_number')"
          :isError="
            hasInputError({
              id: activeQuestion.question.question_uuid,
              minValue: activeQuestion.question.answers.number_field
                ? activeQuestion.question.answers.number_field
                    .number_min_allowed
                : null,
              maxValue: activeQuestion.question.answers.number_field
                ? activeQuestion.question.answers.number_field
                    .number_max_allowed
                : null
            })
          "
          :clearInput="clearInput"
          inputWidth="200px"
          dataId="input_question_page_active_question_answer"
          :question="activeQuestion.question.answers.number_field"
          :value="selectedAnswer[activeQuestion.question.question_uuid].value"
          @input-change="
            onAnswerInput({
              value: $event,
              id: activeQuestion.question.question_uuid,
              variable: activeQuestion.question.variable
            })
          "
          @on-enter-click="onSubmitClick()"
        />
        <error-message
          v-if="
            hasInputError({
              id: activeQuestion.question.question_uuid,
              minValue: activeQuestion.question.answers.number_field
                ? activeQuestion.question.answers.number_field
                    .number_min_allowed
                : null,
              maxValue: activeQuestion.question.answers.number_field
                ? activeQuestion.question.answers.number_field
                    .number_max_allowed
                : null
            }) &&
            !inputOnlyQuestion({
              question: activeQuestion.question,
              id: activeQuestion.question.question_uuid
            })
          "
          >{{
            translate('enter_value_between')
              .replace(
                /%s/,
                activeQuestion.question.answers.number_field
                  ? activeQuestion.question.answers.number_field
                      .number_min_allowed
                  : null
              )
              .replace(
                /%s/,
                activeQuestion.question.answers.number_field
                  ? activeQuestion.question.answers.number_field
                      .number_max_allowed
                  : null
              )
          }}</error-message
        >
      </questions-container>
      <addtional-container
        v-for="(additionalQuestion, index) in activeQuestion.question
          .child_questions"
        :key="additionalQuestion.question_uuid"
      >
        <question-title
          :questionText="additionalQuestion.title"
          :dataId="`text_question_page_additional_question_${index + 1}_title`"
        />
        <questions-container>
          <question-choices
            :dataIdPrefix="`option_question_page_additional_question_${
              index + 1
            }_choice`"
            :question="additionalQuestion.answers"
            :type="additionalQuestion.question_type"
            :selectedAnswer="
              selectedAnswer[additionalQuestion.question_uuid].value
            "
            :clearInput="clearInput"
            :isError="
              hasInputError({
                id: additionalQuestion.question_uuid,
                minValue: additionalQuestion.answers.number_field
                  ? additionalQuestion.answers.number_field.number_min_allowed
                  : null,
                maxValue: additionalQuestion.answers.number_field
                  ? additionalQuestion.answers.number_field.number_max_allowed
                  : null
              })
            "
            @on-answer-selection="
              onAnswerClick({
                value: $event,
                id: additionalQuestion.question_uuid,
                variable: additionalQuestion.variable
              })
            "
            @on-answer-input="
              onAnswerInput({
                value: $event,
                id: additionalQuestion.question_uuid,
                variable: additionalQuestion.variable
              })
            "
            v-if="
              !inputOnlyQuestion({
                question: additionalQuestion,
                id: additionalQuestion.question_uuid
              })
            "
            @on-enter-click="onSubmitClick()"
          />
          <input-number
            v-else
            :placeholder="translate('enter_number')"
            :isError="
              hasInputError({
                id: additionalQuestion.question_uuid,
                minValue: additionalQuestion.answers.number_field
                  ? additionalQuestion.answers.number_field.number_min_allowed
                  : null,
                maxValue: additionalQuestion.answers.number_field
                  ? additionalQuestion.answers.number_field.number_max_allowed
                  : null
              })
            "
            :clearInput="clearInput"
            inputWidth="200px"
            :question="additionalQuestion.answers.number_field"
            :value="selectedAnswer[additionalQuestion.question_uuid].value"
            @input-change="
              onAnswerInput({
                value: $event,
                id: additionalQuestion.question_uuid,
                variable: additionalQuestion.variable
              })
            "
            @on-enter-click="onSubmitClick()"
            :dataId="`input_question_page_additional_question_${
              index + 1
            }_answer`"
          />
          <error-message
            v-if="
              hasInputError({
                id: additionalQuestion.question_uuid,
                minValue: additionalQuestion.answers.number_field
                  ? additionalQuestion.answers.number_field.number_min_allowed
                  : null,
                maxValue: additionalQuestion.answers.number_field
                  ? additionalQuestion.answers.number_field.number_max_allowed
                  : null
              }) &&
              !inputOnlyQuestion({
                question: additionalQuestion,
                id: additionalQuestion.question_uuid
              })
            "
            >{{
              translate('enter_value_between')
                .replace(
                  /%s/,
                  additionalQuestion.answers.number_field
                    ? additionalQuestion.answers.number_field.number_min_allowed
                    : null
                )
                .replace(
                  /%s/,
                  additionalQuestion.answers.number_field
                    ? additionalQuestion.answers.number_field.number_max_allowed
                    : null
                )
            }}</error-message
          >
        </questions-container>
      </addtional-container>
      <button-container v-if="$screen.width > 768">
        <button-component
          type="tertiary"
          dataId="button_question_page_back"
          :text="translate('back')"
          @click.native="onBackClick()"
        />
        <button-component
          :disabled="!isFormComplete() || formHasErrors()"
          type="primary"
          dataId="button_question_page_continue"
          :text="translate('continue')"
          @click.native="onSubmitClick()"
          :style="{
            visibility:
              (showContinueButton && !isFormComplete()) || formHasErrors()
                ? 'hidden'
                : !showContinueButton
                ? 'hidden'
                : 'visible'
          }"
        />
      </button-container>
    </right-container>
    <info-component :text="getInfoText" v-if="$screen.width < 769" />
    <button-mobile-container v-if="$screen.width < 769">
      <button-mobile
        :backArrow="true"
        :text="translate('back')"
        @click.native="onBackClick()"
      />
      <button-mobile
        v-if="showContinueButton"
        :forwardArrow="true"
        :text="translate('continue')"
        :isDisabled="!isFormComplete() || formHasErrors()"
        :isValid="true"
        @click.native="onSubmitClick()"
      />
    </button-mobile-container>
  </page-container>
</template>

<script>
import styled from 'vue-styled-components'
import { mapGetters, mapActions } from 'vuex'
import ProgressBar from '@/components/reusable-components/ProgressBar'
import SelectedAnswers from '@/components/reusable-components/SelectedAnswers'
import InfoComponent from '@/components/reusable-components/InfoComponent'
import QuestionTitle from '@/components/reusable-components/QuestionTitle'
import QuestionChoices from '@/components/reusable-components/QuestionChoices'
import InputNumber from '@/components/reusable-components/InputNumber'
import ButtonComponent from '@/components/reusable-components/ButtonComponent'
import ButtonMobile from '@/components/reusable-components/ButtonMobile'

const pageAttrs = { buttonMargin: Boolean, isEmbedded: Boolean }
const PageContainer = styled('div', pageAttrs)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 50px; // This should only be for Desktop
  margin-top: ${(props) =>
    props.isEmbedded ? '0' : '100px'}; // This should only be for Desktop
  grid-gap: 80px;
  margin-bottom: ${(props) => (props.buttonMargin ? '80px' : '0')};

  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    grid-template-columns: 1fr;
    margin-top: 0;
    grid-gap: 30px;
    grid-template-rows: auto 1fr;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`

const addtionalContainer = styled.div`
  margin-top: 40px;
`

const ErrorMessage = styled.div`
  font-size: 14px;
  text-align: right;
  color: ${(props) => props.theme.colors.red};
  padding-right: 14px;
`

const ButtonMobileContainer = styled.div`
  position: fixed;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  width: 100%;
  padding: 14px 0;
  background: #fff;

  div:nth-child(1) {
    justify-self: flex-start;
    padding-left: 16px;
  }

  div:nth-child(2) {
    justify-self: flex-end;
    padding-right: 16px;
  }
`

const AnswersContainer = styled.div`
  max-height: 500px;
`

const QuestionsContainer = styled.div`
  height: auto;
  max-height: 420px;
  position: relative;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 10px; //width of the whole scrollbar area
  }

  track ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    border-right: 2px solid ${(props) => props.theme.colors.mediumGray}; // width of the actual scrollbar
  }
`

const LeftContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 30px;
  padding-top: 8px; // This is needed to vertically align with the Text that has a line height

  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    grid-template-rows: auto;
  }
`

const RightContainer = styled.div`
  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    padding: 0 16px;
    padding-bottom: 50px;
  }
`

const ButtonContainer = styled.div`
  display: inline-flex;
  margin-top: 40px;

  button:not(:first-child) {
    margin-left: 16px;
  }
`

export default {
  name: 'question-page',
  components: {
    PageContainer,
    LeftContainer,
    RightContainer,
    ProgressBar,
    SelectedAnswers,
    InfoComponent,
    AnswersContainer,
    QuestionTitle,
    QuestionChoices,
    QuestionsContainer,
    ButtonComponent,
    ButtonContainer,
    InputNumber,
    ButtonMobileContainer,
    ButtonMobile,
    addtionalContainer,
    ErrorMessage
  },
  data() {
    return {
      selectedAnswer: {},
      clearInput: false, // triggers the child to clear inputs after continuing
      showContinueButton: false,
      isInput: false
    }
  },
  computed: {
    ...mapGetters({
      activeQuestion: 'getActiveQuestion',
      questionLoading: 'getQuestionLoading',
      getSelectedAnswers: 'getSelectedAnswers',
      getIsEmbedded: 'getIsEmbedded'
    }),
    getPercentComplete() {
      return this.activeQuestion.question_progress * 100
    },
    getInfoText() {
      return this.activeQuestion.question.description
    }
  },
  methods: {
    ...mapActions({
      postQuestionAnswer: 'postQuestionAnswer',
      modifyAnswer: 'postModifyAnswer',
      resetCalculator: 'resetCalculator'
    }),
    onAnswerClick({ value, id, variable }) {
      const questionNumber = this.activeQuestion.answered_questions.length + 1

      let event = {
        name: 'question_' + questionNumber,
        category: 'Input',
        action: 'Clicked',
        description: 'Clicked/Entered ' + value,
        question: variable
      }

      this.analyticsTracking({ event })
      if (this.hasSubQuestions()) {
        this.selectedAnswer[id].value = value
        this.isFormComplete()
      } else {
        this.showContinueButton = false
        this.selectedAnswer[id].value = value
        setTimeout(() => {
          this.onSubmitClick()
        }, 300)
      }
      this.isInput = false
    },
    inputOnlyQuestion({ question }) {
      // If the data type is like this, then we should show the button
      return (
        !!question.answers.number_field &&
        question.answers.number_field.show_input_field &&
        !question.answers.choices
      )
    },
    getSelectedAnswer(id) {
      return this.selectedAnswer[id].value
    },
    hasInputError({ id, minValue, maxValue }) {
      if (maxValue === null || minValue === null) {
        return false
      }
      return (
        parseInt(this.selectedAnswer[id].value) > maxValue ||
        parseInt(this.selectedAnswer[id].value) < minValue
      )
    },
    isFormComplete() {
      const allQuestionKeys = Object.getOwnPropertyNames(this.selectedAnswer)

      let isComplete = true

      allQuestionKeys.map((item) => {
        if (
          this.selectedAnswer[item].value === null ||
          this.selectedAnswer[item].value === ''
        ) {
          isComplete = false
        }
      })
      if (this.hasSubQuestions()) {
        this.showContinueButton = isComplete
      } else if (
        !!this.activeQuestion.question.answers.number_field &&
        this.activeQuestion.question.answers.number_field.show_input_field
      ) {
        this.showContinueButton = isComplete && this.isInput
      }
      return isComplete
    },
    formHasErrors() {
      let hasErrors = false
      if (this.hasSubQuestions()) {
        // If this has multiple sub questions
        this.activeQuestion.question.child_questions.map((item) => {
          if (!!item.answers.number_field) {
            const answer = this.selectedAnswer[item.question_uuid].value
            const number = item.answers.number_field
            if (
              answer > number.number_max_allowed ||
              answer < number.number_min_allowed
            ) {
              hasErrors = true
            }
          }
        })
      } else {
        const answer =
          this.selectedAnswer[this.activeQuestion.question.question_uuid].value
        if (!!this.activeQuestion.question.answers.number_field) {
          const number = this.activeQuestion.question.answers.number_field
          if (
            answer > number.number_max_allowed ||
            answer < number.number_min_allowed
          ) {
            hasErrors = true
          }
        }
      }
      return hasErrors
    },
    onAnswerInput({ value, id, variable }) {
      this.selectedAnswer[id].value = value
      this.isInput = true
      const questionNumber = this.activeQuestion.answered_questions.length + 1

      let event = {
        name: 'question_' + questionNumber,
        category: 'Input',
        action: 'Clicked',
        description: 'Clicked/Entered ' + value,
        question: variable
      }
      this.analyticsTracking({ event })
      this.isFormComplete()
    },
    onSubmitClick() {
      if (!this.isFormComplete() || this.formHasErrors()) {
        return
      }
      const allQuestionKeys = Object.getOwnPropertyNames(this.selectedAnswer)
      const questionNumber = this.activeQuestion.answered_questions.length + 1

      let event = {
        name: 'question_' + questionNumber + '_next_button',
        category: 'Input',
        action: 'Clicked',
        description: 'Click on Next button'
      }
      this.analyticsTracking({ event })
      const dataToSend = []
      allQuestionKeys.map((item) => {
        dataToSend.push(this.selectedAnswer[item])
      })
      this.postQuestionAnswer({ data: dataToSend }).then((res) => {
        if (res !== 'error') {
          this.showContinueButton = false
        }
      })
    },
    hasSubQuestions() {
      return this.activeQuestion.question.child_questions.length
    },
    onBackClick() {
      const amount = this.getSelectedAnswers.length
      if (!amount) {
        return this.resetCalculator()
      }
      const data = {
        activity_id: this.getSelectedAnswers[amount - 1].activity_id
      }
      this.modifyAnswer({ data })
    },
    onQuestionInit() {
      window.scrollTo(0, 0)
      // Trigger the child to clear the input
      this.clearInput = true
      this.selectedAnswer = {}
      if (this.hasSubQuestions()) {
        // If this has multiple sub questions
        this.activeQuestion.question.child_questions.map((item) => {
          this.selectedAnswer = {
            ...this.selectedAnswer,
            [item.question_uuid]: {
              variable: item.variable,
              question_type: item.question_type,
              value: null
            }
          }
        })
      } else {
        const question = this.activeQuestion.question
        this.selectedAnswer = {
          [question.question_uuid]: {
            variable: question.variable,
            question_type: question.question_type,
            value: null
          }
        }
      }
      delete this.selectedAnswer['__ob__']
      setTimeout(() => {
        this.clearInput = false
      }, 1000)
    }
  },
  created() {
    this.onQuestionInit()
  },
  watch: {
    activeQuestion: function () {
      // We should close the modal after a success/error
      this.onQuestionInit()
    }
  }
}
</script>
