<template>
  <spinner-container v-if="fullWidth">
    <container>
      <spinner-wrapper :src="require('@/assets/images/black_spinner.svg')" />
    </container>
  </spinner-container>
  <container v-else>
    <spinner-wrapper :src="require('@/assets/images/black_spinner.svg')" />
  </container>
</template>

<script>
import styled from 'vue-styled-components'

const SpinnerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: grid;
  align-items: center;
  justify-items: center;
  z-index: 100;
`

const Container = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
`

const SpinnerWrapper = styled.img`
  width: 120px;
`

export default {
  name: 'spinner',
  components: {
    Container,
    SpinnerWrapper,
    SpinnerContainer
  },
  props: {
    fullWidth: {
      required: false,
      default: false
    }
  }
}
</script>
