var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.activeQuestion)?_c('page-container',{attrs:{"buttonMargin":_vm.showContinueButton,"isEmbedded":_vm.getIsEmbedded}},[_c('left-container',[_c('progress-bar',{attrs:{"fillProgress":_vm.getPercentComplete,"stepNumber":_vm.activeQuestion.answered_questions.length + 1}}),(_vm.$screen.width > 768)?_c('answers-container',[_c('selected-answers',{attrs:{"options":_vm.activeQuestion.answered_questions,"maxHeight":500}})],1):_vm._e()],1),_c('right-container',[_c('question-title',{attrs:{"questionText":_vm.activeQuestion.question.title,"infoText":_vm.getInfoText,"dataId":"text_question_page_active_question_title"}}),(Object.entries(_vm.activeQuestion.question.answers).length)?_c('questions-container',[(!_vm.inputOnlyQuestion({ question: _vm.activeQuestion.question }))?_c('question-choices',{attrs:{"dataIdPrefix":"option_question_page_active_question_choice","question":_vm.activeQuestion.question.answers,"selectedAnswer":_vm.getSelectedAnswer(_vm.activeQuestion.question.question_uuid),"clearInput":_vm.clearInput,"isError":_vm.hasInputError({
            id: _vm.activeQuestion.question.question_uuid,
            minValue: _vm.activeQuestion.question.answers.number_field
              ? _vm.activeQuestion.question.answers.number_field
                  .number_min_allowed
              : null,
            maxValue: _vm.activeQuestion.question.answers.number_field
              ? _vm.activeQuestion.question.answers.number_field
                  .number_max_allowed
              : null
          }),"type":_vm.activeQuestion.question.question_type},on:{"on-answer-selection":function($event){return _vm.onAnswerClick({
            value: $event,
            id: _vm.activeQuestion.question.question_uuid,
            variable: _vm.activeQuestion.question.variable
          })},"on-answer-input":function($event){return _vm.onAnswerInput({
            value: $event,
            id: _vm.activeQuestion.question.question_uuid,
            variable: _vm.activeQuestion.question.variable
          })},"on-enter-click":function($event){return _vm.onSubmitClick()}}}):_c('input-number',{attrs:{"placeholder":_vm.translate('enter_number'),"isError":_vm.hasInputError({
            id: _vm.activeQuestion.question.question_uuid,
            minValue: _vm.activeQuestion.question.answers.number_field
              ? _vm.activeQuestion.question.answers.number_field
                  .number_min_allowed
              : null,
            maxValue: _vm.activeQuestion.question.answers.number_field
              ? _vm.activeQuestion.question.answers.number_field
                  .number_max_allowed
              : null
          }),"clearInput":_vm.clearInput,"inputWidth":"200px","dataId":"input_question_page_active_question_answer","question":_vm.activeQuestion.question.answers.number_field,"value":_vm.selectedAnswer[_vm.activeQuestion.question.question_uuid].value},on:{"input-change":function($event){return _vm.onAnswerInput({
            value: $event,
            id: _vm.activeQuestion.question.question_uuid,
            variable: _vm.activeQuestion.question.variable
          })},"on-enter-click":function($event){return _vm.onSubmitClick()}}}),(
          _vm.hasInputError({
            id: _vm.activeQuestion.question.question_uuid,
            minValue: _vm.activeQuestion.question.answers.number_field
              ? _vm.activeQuestion.question.answers.number_field
                  .number_min_allowed
              : null,
            maxValue: _vm.activeQuestion.question.answers.number_field
              ? _vm.activeQuestion.question.answers.number_field
                  .number_max_allowed
              : null
          }) &&
          !_vm.inputOnlyQuestion({
            question: _vm.activeQuestion.question,
            id: _vm.activeQuestion.question.question_uuid
          })
        )?_c('error-message',[_vm._v(_vm._s(_vm.translate('enter_value_between') .replace( /%s/, _vm.activeQuestion.question.answers.number_field ? _vm.activeQuestion.question.answers.number_field .number_min_allowed : null ) .replace( /%s/, _vm.activeQuestion.question.answers.number_field ? _vm.activeQuestion.question.answers.number_field .number_max_allowed : null )))]):_vm._e()],1):_vm._e(),_vm._l((_vm.activeQuestion.question
        .child_questions),function(additionalQuestion,index){return _c('addtional-container',{key:additionalQuestion.question_uuid},[_c('question-title',{attrs:{"questionText":additionalQuestion.title,"dataId":`text_question_page_additional_question_${index + 1}_title`}}),_c('questions-container',[(
            !_vm.inputOnlyQuestion({
              question: additionalQuestion,
              id: additionalQuestion.question_uuid
            })
          )?_c('question-choices',{attrs:{"dataIdPrefix":`option_question_page_additional_question_${
            index + 1
          }_choice`,"question":additionalQuestion.answers,"type":additionalQuestion.question_type,"selectedAnswer":_vm.selectedAnswer[additionalQuestion.question_uuid].value,"clearInput":_vm.clearInput,"isError":_vm.hasInputError({
              id: additionalQuestion.question_uuid,
              minValue: additionalQuestion.answers.number_field
                ? additionalQuestion.answers.number_field.number_min_allowed
                : null,
              maxValue: additionalQuestion.answers.number_field
                ? additionalQuestion.answers.number_field.number_max_allowed
                : null
            })},on:{"on-answer-selection":function($event){return _vm.onAnswerClick({
              value: $event,
              id: additionalQuestion.question_uuid,
              variable: additionalQuestion.variable
            })},"on-answer-input":function($event){return _vm.onAnswerInput({
              value: $event,
              id: additionalQuestion.question_uuid,
              variable: additionalQuestion.variable
            })},"on-enter-click":function($event){return _vm.onSubmitClick()}}}):_c('input-number',{attrs:{"placeholder":_vm.translate('enter_number'),"isError":_vm.hasInputError({
              id: additionalQuestion.question_uuid,
              minValue: additionalQuestion.answers.number_field
                ? additionalQuestion.answers.number_field.number_min_allowed
                : null,
              maxValue: additionalQuestion.answers.number_field
                ? additionalQuestion.answers.number_field.number_max_allowed
                : null
            }),"clearInput":_vm.clearInput,"inputWidth":"200px","question":additionalQuestion.answers.number_field,"value":_vm.selectedAnswer[additionalQuestion.question_uuid].value,"dataId":`input_question_page_additional_question_${
            index + 1
          }_answer`},on:{"input-change":function($event){return _vm.onAnswerInput({
              value: $event,
              id: additionalQuestion.question_uuid,
              variable: additionalQuestion.variable
            })},"on-enter-click":function($event){return _vm.onSubmitClick()}}}),(
            _vm.hasInputError({
              id: additionalQuestion.question_uuid,
              minValue: additionalQuestion.answers.number_field
                ? additionalQuestion.answers.number_field.number_min_allowed
                : null,
              maxValue: additionalQuestion.answers.number_field
                ? additionalQuestion.answers.number_field.number_max_allowed
                : null
            }) &&
            !_vm.inputOnlyQuestion({
              question: additionalQuestion,
              id: additionalQuestion.question_uuid
            })
          )?_c('error-message',[_vm._v(_vm._s(_vm.translate('enter_value_between') .replace( /%s/, additionalQuestion.answers.number_field ? additionalQuestion.answers.number_field.number_min_allowed : null ) .replace( /%s/, additionalQuestion.answers.number_field ? additionalQuestion.answers.number_field.number_max_allowed : null )))]):_vm._e()],1)],1)}),(_vm.$screen.width > 768)?_c('button-container',[_c('button-component',{attrs:{"type":"tertiary","dataId":"button_question_page_back","text":_vm.translate('back')},nativeOn:{"click":function($event){return _vm.onBackClick()}}}),_c('button-component',{style:({
          visibility:
            (_vm.showContinueButton && !_vm.isFormComplete()) || _vm.formHasErrors()
              ? 'hidden'
              : !_vm.showContinueButton
              ? 'hidden'
              : 'visible'
        }),attrs:{"disabled":!_vm.isFormComplete() || _vm.formHasErrors(),"type":"primary","dataId":"button_question_page_continue","text":_vm.translate('continue')},nativeOn:{"click":function($event){return _vm.onSubmitClick()}}})],1):_vm._e()],2),(_vm.$screen.width < 769)?_c('info-component',{attrs:{"text":_vm.getInfoText}}):_vm._e(),(_vm.$screen.width < 769)?_c('button-mobile-container',[_c('button-mobile',{attrs:{"backArrow":true,"text":_vm.translate('back')},nativeOn:{"click":function($event){return _vm.onBackClick()}}}),(_vm.showContinueButton)?_c('button-mobile',{attrs:{"forwardArrow":true,"text":_vm.translate('continue'),"isDisabled":!_vm.isFormComplete() || _vm.formHasErrors(),"isValid":true},nativeOn:{"click":function($event){return _vm.onSubmitClick()}}}):_vm._e()],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }