const state = {
  list: []
}

const getters = {
  getNotificationsList(state) {
    return state.list
  }
}

const mutations = {
  setAddNotification(state, payload) {
    state.list.push(payload)
  },
  setRemoveNotification(state, id) {
    state.list = state.list.filter((item) => {
      return item.id !== id
    })
  }
}

const actions = {
  addNotification({ commit }, { type, text }) {
    // To use:
    //   this.addNotification({
    //     text: 'my_translation_key',
    //     type: 'error'
    //   })
    let uuid = Math.floor(Math.random() * 100000)
    const info = {
      id: uuid,
      text,
      type // success, warning, error, info
    }
    commit('setAddNotification', info)

    setTimeout(() => {
      commit('setRemoveNotification', uuid)
    }, 2000)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
