<template>
  <container>
    <wrapper v-for="item in notificationsList" :key="item.id" :type="item.type">
      {{ translate(item.text) }}
    </wrapper>
  </container>
</template>

<script>
// To use:
//   this.addNotification({
//     text: 'my_translation_string',
//     type: 'error'
//   })
import styled from 'vue-styled-components'
import { mapGetters } from 'vuex'

const notificationAttrs = { type: String }
const Wrapper = styled('div', notificationAttrs)`
  color: #fff;
  background-color: ${(props) =>
    props.type === 'success'
      ? '#009688'
      : props.type === 'info'
      ? '#505ca6'
      : props.type === 'warning'
      ? '#fdb813'
      : props.type === 'error'
      ? '#ff5656'
      : '#fdb813'};
  border-radius: 4px;
  padding: 6px 12px;
`

const Container = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99999;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
`

export default {
  name: 'notification',
  components: {
    Wrapper,
    Container
  },
  computed: {
    ...mapGetters({
      notificationsList: 'getNotificationsList'
    })
  }
}
</script>
