<template>
  <wrapper>
    <container>
      <left-line :color="color" />
      <right-line :color="color" />
    </container>
  </wrapper>
</template>

<script>
// To use:
// <close-button
//   color="#fff"
// />
import styled from 'vue-styled-components'

const Container = styled.div`
  position: relative;
  width: 26px;
  height: 25px;
`

const Wrapper = styled.div`
  display: inline-block;
  cursor: pointer;
`

const lineAttrs = { color: String }
const Line = styled('div', lineAttrs)`
  width: 26px;
  height: 2px;
  background-color: ${(props) =>
    props.color ? props.color : props.theme.colors.primary};
  position: absolute;
  top: 11px; //half of the height of the parent
`

const LeftLine = styled(Line)`
  transform: rotate(135deg);
`

const RightLine = styled(Line)`
  transform: rotate(45deg);
`

export default {
  name: 'close-button',
  components: {
    Container,
    Wrapper,
    LeftLine,
    RightLine
  },
  props: {
    color: {
      required: false
    }
  }
}
</script>
