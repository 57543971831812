<template>
  <container :isDisabled="isDisabled" :isValid="isValid">
    <arrow-left v-if="backArrow" :isDisabled="isDisabled" :isValid="isValid" />
    <button-text :fontSize="fontSize">{{ text }}</button-text>
    <arrow-right
      v-if="forwardArrow"
      :isDisabled="isDisabled"
      :isValid="isValid"
    />
  </container>
</template>

<script>
//To use:
// <button-mobile
//   :forwardArrow="true" //optional
//   :backArrow="true" //optional
//   :text="translate('Continue')" //optional
//   :isDisabled="false" //optional
//   :isValid="true" //optional. Should be true if the color should be blue
//   fontSize="14px" //optional
// />
import styled from 'vue-styled-components'

const containerProps = {
  isDisabled: Boolean,
  isValid: Boolean
}
const Container = styled('div', containerProps)`
  display: inline-flex;
  grid-gap: 6px;
  align-items: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  color: ${(props) =>
    props.isValid && !props.isDisabled
      ? props.theme.colors.blue
      : props.theme.colors.mediumGray};
`

const Arrow = styled('div', containerProps)`
  height: 1px;
  background-color: ${(props) =>
    props.isValid && !props.isDisabled
      ? props.theme.colors.blue
      : props.theme.colors.mediumGray};
  position: relative;
  width: 24px;
`
const ArrowRight = styled(Arrow)`
  &:after {
    position: absolute;
    content: '>';
    right: 0;
    top: -9px;
  }
`

const ArrowLeft = styled(Arrow)`
  &:after {
    position: absolute;
    content: '<';
    left: 0;
    top: -9px;
  }
`

const buttonAttrs = { fontSize: String }
const buttonText = styled('div', buttonAttrs)`
  color: inherit;
  font-size: ${(props) => (props.fontSize ? props.fontSize + 'px' : '20px')};
`

export default {
  name: 'button-mobile',
  components: {
    Container,
    buttonText,
    ArrowRight,
    ArrowLeft
  },
  props: {
    backArrow: {
      required: false,
      default: false
    },
    forwardArrow: {
      required: false,
      default: false
    },
    text: {
      require: false,
      type: String,
      default: ''
    },
    isDisabled: {
      required: false,
      type: Boolean,
      default: false
    },
    isValid: {
      required: false,
      type: Boolean,
      default: false
    },
    fontSize: {
      required: false
    }
  }
}
</script>
