<template>
  <title-text :noMargin="noMargin" class="question-title" :data-id="dataId">
    <span v-html="questionText" />
    <info-container v-if="infoText && infoText.length"
      ><info-component :text="infoText" /></info-container
  ></title-text>
</template>

<script>
// How to use:
// <question-title
//     :questionText="translate('question_e_mobility_3')"
//     data-id="test_data_id"
// />

import styled from 'vue-styled-components'
import InfoComponent from '@/components/reusable-components/InfoComponent'

const titleAttrs = { noMargin: Boolean }
const TitleText = styled('div', titleAttrs)`
  color: ${(props) => props.theme.colors.black};
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: ${(props) => (props.noMargin ? '0' : '30px')};
`

const InfoContainer = styled.span`
  display: inline-grid;
  margin-left: 12px;
  line-height: 21px;
`

export default {
  name: 'question-title',
  components: {
    TitleText,
    InfoComponent,
    InfoContainer
  },
  props: {
    questionText: {
      required: true,
      type: String,
      default: null
    },
    noMargin: {
      required: false,
      default: false
    },
    infoText: {
      required: false,
      default: null
    },
    dataId: {
      type: String,
      default: ''
    }
  }
}
</script>
