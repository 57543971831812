import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import VueScreen from 'vue-screen'
import VueCompositionAPI from '@vue/composition-api'
import '@/assets/scss/main.scss'
import store from '@/store/store'
import VueCookies from 'vue-cookies'
import VueGtag from 'vue-gtag'
import VueGtm from 'vue-gtm'
import tokens from '@/assets/tokens.json'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

Vue.use(VueCookies)

Vue.use(VueMeta)

Vue.use(VueScreen)

Vue.use(VueCompositionAPI)

const storageLang = localStorage.getItem('lang')

function initGoogleAnalytics() {
  let apiUrl = process.env.VUE_APP_URL
  const path = location.pathname
    .replace(/\/configurator/g, '')
    .replace(/\//g, '')
  let token = tokens.companies[path]
  axios
    .get(`${apiUrl}configurator/configurator_settings/${token}/`)
    .then((res) => {
      const storageCurrency = localStorage.getItem('lang')
      const languages = res.data.language
      const urlParams = new URLSearchParams(window.location.search)
      let urlLang = urlParams.get('lang')
      if (languages.length && urlLang) {
        const selectedLang = languages.filter((lang) => {
          return lang.language === urlLang
        })
        if (selectedLang.length) {
          localStorage.setItem('currency', selectedLang[0].currency)
          localStorage.setItem('lang', selectedLang[0].language)
        }
      } else if (!languages.some((e) => e.language === storageLang)) {
        if (!storageLang || !storageCurrency) {
          const defaultLang = languages.filter((x) => {
            return !!x.default
          })
          const langToSet =
            defaultLang && defaultLang.length ? defaultLang[0] : languages[0]
          localStorage.setItem('lang', langToSet.language)
          localStorage.setItem('currency', langToSet.currency)
        }
      }
      const data = res.data
      if (
        data.calculator_configuration.analytics_type === 'google_tag_manager' &&
        data.calculator_configuration.analytics_id
      ) {
        Vue.use(VueGtm, {
          id: data.calculator_configuration.analytics_id,
          debug: false,
          enabled: true
        })
      }

      if (
        (data.calculator_configuration.analytics_type === 'google_analytics' ||
          data.calculator_configuration.analytics_type ===
            'google_analytics_4') &&
        data.calculator_configuration.analytics_id
      ) {
        Vue.use(VueGtag, {
          config: {
            id: data.calculator_configuration.analytics_id,
            params: {
              cookie_flags: 'SameSite=None;Secure',
              client_storage: 'none'
            }
          },
          params: {
            send_page_view: true
          }
        })
      }
    })
}
initGoogleAnalytics()

Vue.mixin({
  methods: {
    translate: (key) => {
      const allTranslations = localStorage.getItem('translations')
      if (!allTranslations) {
        return key
      }
      const translations = JSON.parse(allTranslations)
      const selected = translations.filter((item) => {
        return item.msgid === key
      })
      // ToDo: how to handle plural?
      // if the translation does not exist, return the key
      if (selected && selected.length) {
        return selected[0].msgstr
      } else {
        return key
      }
    },
    analyticsTracking: ({ event }) => {
      // Pass the params like this:
      //
      // let event = {
      //   name: 'new_customer',
      //   category: 'My Category',
      //   action: 'My Action!',
      //   description: 'My description'
      // }
      // this.analyticsTracking({ event })
      const urlParams = new URLSearchParams(window.location.search)
      let cookiePopup = urlParams.get('cookies_popup')

      let settings = store.getters.getCompanySettings
      let cookiesAccepted = store.getters.getAllowCookies

      if (
        cookiePopup === 'false' ||
        settings.calculator_configuration.gdpr_compliance === false ||
        cookiesAccepted
      ) {
        // only track if one of the above is true
        if (
          settings.calculator_configuration.analytics_id &&
          (settings.calculator_configuration.analytics_type ===
            'google_analytics' ||
            settings.calculator_configuration.analytics_type ===
              'google_analytics_4' ||
            settings.calculator_configuration.analytics_type ===
              'google_analytics_3')
        ) {
          Vue.$gtag.event(event.name, {
            event_category: event.category,
            event_action: event.action,
            event_description: event.description,
            question: event.question,
            eturnity_app: 'configurator'
          })
        } else if (
          settings.calculator_configuration.analytics_id &&
          settings.calculator_configuration.analytics_type ===
            'google_tag_manager'
        ) {
          Vue.gtm.trackEvent({
            event: event.name, //if null, this defaults to 'interaction'
            category: event.category,
            action: event.action,
            label: event.description,
            question: event.question,
            eturnity_app: 'configurator'
          })
        }
      }
      if (
        settings.calculator_configuration.analytics_type === 'postMessage_api'
      ) {
        // We should always send this message to the parent if postMessage_api is active
        window.parent.postMessage(
          {
            trackingEvent: {
              event: event.name,
              category: event.category,
              action: event.action,
              label: event.description,
              question: event.question,
              eturnity_app: 'configurator'
            }
          },
          '*'
        )
      }
    }
  }
})

new Vue({
  store,
  render: (h) => h(App)
}).$mount('#app')
