<template>
  <page-wrapper>
    <page-container :questionType="type" :numQuestions="getQuestions().length">
      <box-container
        v-for="(item, index) in getQuestions()"
        :key="item.choice"
        @click="onAnswerClick(item.choice)"
        :isSelected="selectedAnswer === item.choice"
        :hasInput="
          question.number_field && question.number_field.show_input_field
        "
        :hasIcon="!!item.image"
        :data-id="`${dataIdPrefix}_${index + 1}`"
      >
        <question-icon v-if="item.image">
          <icon-container :src="item.image" />
        </question-icon>
        <question-text
          :hasIcon="!!item.image"
          :isSelected="selectedAnswer === item.choice"
          >{{
            item.name && item.name.length ? item.name : item.choice
          }}</question-text
        >
      </box-container>
      <input-text
        @input="onAnswerType($event)"
        @blur="onInputBlur()"
        :value="textInput"
        v-if="question.number_field && question.number_field.show_input_field"
        type="text"
        :isError="isError"
        :isActive="!!textInput.length"
        :placeholder="translate('enter_number')"
        @keyup.enter="$emit('on-enter-click')"
      />
    </page-container>
  </page-wrapper>
</template>

<script>
// To use:
// <question-choices
//   :question="question"
//   :selectedAnswer="selectedAnswer"
//   @on-answer-selection="onAnswerClick($event)"
//   @on-enter-click="onInputSubmit()"
//   :type="question.type"
//   dataIdPrefix="test_data_id_prefix"
// />

import styled from 'vue-styled-components'
import {
  stringToNumber,
  numberToString
} from '@eturnity/eturnity_reusable_components/src/helpers/numberConverter'

const PageWrapper = styled.div`
  max-height: 100%;
  width: 100%;
`

const layoutProps = { questionType: String, numQuestions: Number }
const PageContainer = styled('div', layoutProps)`
  /* grid-column-gap: ${(props) =>
    props.questionType === 'choice_question' ? '30px' : '10px'}; */
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  flex-direction: row;

  @media (max-width: ${(props) => props.theme.screen.mobileLarge}) {
    display: grid;
    grid-template-columns: 1fr;
  }
`

const selectionProps = {
  isSelected: Boolean,
  hasIcon: Boolean,
  hasInput: Boolean
}
const BoxContainer = styled('div', selectionProps)`
  display: grid;
  grid-template-columns: ${(props) => (props.hasIcon ? 'auto 1fr' : '1fr')};
  grid-gap: 20px;
  align-items: center;
  justify-items: ${(props) => (props.hasIcon ? 'flex-start' : 'center')};
  cursor: pointer;
  border: 1px solid
    ${(props) =>
      props.isSelected
        ? props.theme.colors.primary
        : props.theme.colors.mediumGray};
  padding: 10px;
  border-radius: 4px;
  flex: ${(props) => (props.hasInput ? '0' : '1')};
  margin: 0 15px 15px 0;
  text-align: center;

  &:hover {
    @media (min-width: ${(props) => props.theme.screen.tablet}) {
      border: ${(props) => `1px solid ${props.theme.colors.primary}`};
    }
  }
`

const inputAttrs = { isActive: Boolean, isError: Boolean }
const InputText = styled('input', inputAttrs)`
  min-width: 200px;
  border: 1px solid
    ${(props) =>
      props.isError
        ? props.theme.colors.red
        : props.isActive
        ? props.theme.colors.primary
        : props.theme.colors.mediumGray};
  border-radius: 4px;
  font-size: 16px;
  padding: 10px 6px 10px 16px;
  color: ${(props) =>
    props.isError ? props.theme.colors.red : props.theme.colors.primary};
  font-weight: 500;
  margin: 0 15px 15px 0;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.mediumGray};
    font-weight: 400;
  }
`

const QuestionIcon = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
`

// IconContainer is a "hack" for overriding the <svg /> icons that are being passed from the API

const IconContainer = styled.img`
  margin: auto 0;
  width: 100%;
  height: auto;
`
const QuestionText = styled('div', selectionProps)`
  font-weight: 500;
  font-size: 18px;
  color: ${(props) =>
    props.isSelected ? props.theme.colors.primary : props.theme.colors.black};
`

export default {
  name: 'question-choices',
  components: {
    PageContainer,
    BoxContainer,
    QuestionText,
    QuestionIcon,
    PageWrapper,
    InputText,
    IconContainer
  },
  data() {
    return {
      textInput: '',
      numberPrecision: 0 // we set this on created. By default is 0
    }
  },
  props: {
    question: {
      required: true,
      default: []
    },
    isError: {
      required: false,
      default: false
    },
    selectedAnswer: {
      required: true,
      default: null
    },
    type: {
      required: true,
      default: null
    },
    clearInput: {
      required: false,
      default: false
    },
    dataIdPrefix: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    onAnswerClick(value) {
      this.textInput = ''
      this.$nextTick(() => {
        // We need the next tick so that the input can be cleared first
        this.$emit('on-answer-selection', value)
      })
    },
    onInputBlur() {
      let num = stringToNumber({
        value: this.textInput,
        numberPrecision: this.numberPrecision
      })
      this.textInput = numberToString({
        value: num,
        numberPrecision: this.numberPrecision
      })
    },
    onAnswerType(value) {
      this.textInput = value
      let formattedValue = stringToNumber({
        value: value,
        numberPrecision: this.numberPrecision
      })
      if (isNaN(formattedValue)) {
        this.textInput = ''
        formattedValue = ''
      }
      this.$emit('on-answer-input', formattedValue)
    },
    getQuestions() {
      if (this.type === 'quantity_question') {
        let questions = []
        // since this is only an array of numbers, we need to convert this to an object to match the other question style
        this.question.choices.map((item) => {
          questions.push({
            choice: item.choice,
            text: item.choice,
            id: item.answer_uuid
          })
        })
        return questions
      } else {
        return this.question.choices
      }
    }
  },
  created() {
    if (this.type === 'quantity_question') {
      this.numberPrecision = this.question.number_field.number_format_precision
      if (this.question.choices.some((x) => x.choice === this.selectedAnswer)) {
        return
      } else {
        if (this.selectedAnswer) {
          // if not null
          this.textInput = numberToString({
            value: this.selectedAnswer,
            numberPrecision: this.numberPrecision
          })
        }
      }
    }
  },
  watch: {
    clearInput: function (value) {
      if (value) {
        // If the value is typed, then we should clear the textInput on Continue
        this.textInput = ''
      }
    }
  }
}
</script>
