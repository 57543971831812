<template>
  <container :align="align" v-if="!menuOpened">
    <logo :src="companyLogo" :align="align" v-if="!getIsEmbedded" />
    <logo :align="align" v-else />
    <mobile-icons :align="align" @click="onMenuClick">
      <hamburger :src="require('@/assets/icons/hamburger_icon.png')" />
    </mobile-icons>
  </container>
  <mobile-container v-else>
    <header-overlay :align="align">
      <logo :src="companyLogo" :align="align" v-if="!getIsEmbedded" />
      <logo :align="align" v-else />
      <mobile-icons :align="align" @click="onMenuClick">
        <close-button />
      </mobile-icons>
    </header-overlay>
    <footer-overlay>
      <footer-component @on-language-selected="onMenuClick" />
    </footer-overlay>
  </mobile-container>
</template>

<script>
import styled from 'vue-styled-components'
import CloseButton from '@/components/reusable-components/CloseButton'
import FooterComponent from '@/components/FooterComponent'
import { mapGetters, mapMutations } from 'vuex'

const MobileContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
`

const logoAttrs = { align: String }
const HeaderOverlay = styled('div', logoAttrs)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 12px 0;
  background: white;
  height: 54px;
  align-items: center;
  grid-template-areas: ${(props) =>
    props.align === 'left' ? '"logo hamburger"' : '"hamburger logo"'};

  @media (max-width: ${(props) => props.theme.screen.tabletLarge}) {
    padding: 12px 16px;
  }
`

const Container = styled('div', logoAttrs)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 12px 0;
  align-items: center;
  grid-template-areas: ${(props) =>
    props.align === 'left' ? '"logo hamburger"' : '"hamburger logo"'};

  @media (max-width: ${(props) => props.theme.screen.tabletLarge}) {
    padding: 12px 16px;
  }
`

const Logo = styled('img', logoAttrs)`
  height: 30px;
  justify-self: ${(props) =>
    props.align === 'left' ? 'flex-start' : 'flex-end'};
  grid-area: logo;

  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    height: 24px;
  }
`

const MobileIcons = styled('div', logoAttrs)`
  justify-self: ${(props) =>
    props.align === 'left' ? 'flex-end' : 'flex-start'};
  display: inline-block;

  display: none;

  @media (max-width: ${(props) => props.theme.screen.tabletLarge}) {
    display: block;
  }
`

const Hamburger = styled('img', logoAttrs)`
  grid-area: hamburger;
  cursor: pointer;
`

const FooterOverlay = styled.div`
  position: fixed;
  top: 54px;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.85);

  display: none;

  @media (max-width: ${(props) => props.theme.screen.tabletLarge}) {
    display: flex;
    align-items: flex-end;
    z-index: 99999;
  }
`

export default {
  name: 'header-component',
  components: {
    Container,
    Logo,
    Hamburger,
    MobileIcons,
    CloseButton,
    FooterComponent,
    FooterOverlay,
    HeaderOverlay,
    MobileContainer
  },
  props: {
    companyLogo: {
      required: true,
      default: ''
    },
    align: {
      required: false,
      default: 'left'
    }
  },
  data() {
    return {
      menuOpened: false
    }
  },
  methods: {
    ...mapMutations({
      setShowMobileFooter: 'setShowMobileFooter'
    }),
    onMenuClick() {
      this.menuOpened = !this.menuOpened
      document.body.style.overflow = this.menuOpened ? 'hidden' : ''
      if (!this.menuOpened) {
        this.setShowMobileFooter(false)
      }
    }
  },
  computed: {
    ...mapGetters({
      getShowMobileFooter: 'getShowMobileFooter',
      getIsEmbedded: 'getIsEmbedded'
    })
  },
  created() {
    // make sure that this defaults to ''
    document.body.style.overflow = ''
  },
  watch: {
    getShowMobileFooter: function (newVal) {
      // We should close the modal after a success
      if (newVal) {
        this.menuOpened = true
        document.body.style.overflow = 'hidden'
      }
    }
  }
}
</script>
