var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"is-open":_vm.isOpen,"track-scroll":_vm.isGoogleAddressRequired},on:{"on-close":function($event){return _vm.onCloseModal()},"on-scroll":function($event){return _vm.setGoogleAddressDropdownTop()}}},[_c('form-container',[_c('title-container',[_c('question-title',{attrs:{"questionText":_vm.translate('request_title')}})],1),(_vm.translate('request_title_subtext') !== 'request_title_subtext')?_c('subtitle-container',{domProps:{"innerHTML":_vm._s(_vm.translate('request_title_subtext'))}}):_vm._e(),(
        _vm.companySettings.calculator_configuration.request_ask_customer_type ===
        'on'
      )?_c('row-wrapper',{attrs:{"numCols":2}},[_c('radio-button',{attrs:{"dataId":"radio_pdf_summary_customer_type","options":_vm.getCustomerType(),"selectedOption":_vm.form.customer_type,"layout":"horizontal"},on:{"on-radio-change":function($event){return _vm.onInputChange({ value: $event, type: 'customer_type' })}}}),_c('input-component',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.customer_type === 'company'),expression:"form.customer_type === 'company'"}],attrs:{"placeholder":_vm.translate('company_name'),"value":_vm.form.company_name,"dataId":"input_pdf_summary_company_name"},on:{"input-change":function($event){return _vm.onInputChange({ value: $event, type: 'company_name' })}}})],1):_vm._e(),_c('row-wrapper',{attrs:{"numCols":2}},[_c('input-component',{attrs:{"placeholder":_vm.translate('first_name') +
          ` ${
            _vm.companySettings.calculator_configuration
              .request_name_compulsory === 'on'
              ? '*'
              : ''
          }`,"value":_vm.form.first_name,"isError":_vm.checkErrors &&
          _vm.companySettings.calculator_configuration.request_name_compulsory ===
            'on' &&
          !_vm.form.first_name.length,"dataId":"input_pdf_summary_first_name"},on:{"input-change":function($event){return _vm.onInputChange({ value: $event, type: 'first_name' })}}}),_c('input-component',{attrs:{"placeholder":_vm.translate('last_name') +
          ` ${
            _vm.companySettings.calculator_configuration
              .request_name_compulsory === 'on'
              ? '*'
              : ''
          }`,"value":_vm.form.last_name,"isError":_vm.checkErrors &&
          _vm.companySettings.calculator_configuration.request_name_compulsory ===
            'on' &&
          !_vm.form.last_name.length,"dataId":"input_pdf_summary_last_name"},on:{"input-change":function($event){return _vm.onInputChange({ value: $event, type: 'last_name' })}}})],1),(_vm.showGenderOptions)?_c('radio-wrapper',[_c('radio-button',{attrs:{"options":_vm.getGenderType(),"selectedOption":_vm.form.gender,"dataId":"radio_pdf_summary_gender","layout":"horizontal"},on:{"on-radio-change":function($event){return _vm.onInputChange({ value: $event, type: 'gender' })}}})],1):_vm._e(),(_vm.isGoogleAddressRequired)?_c('row-wrapper',[_c('google-input-wrapper',{attrs:{"isError":_vm.checkErrors && _vm.isGoogleAddressInvalid}},[_c('vue-google-autocomplete',{ref:"googleAddress",attrs:{"id":"map","placeholder":_vm.translate('address') + ' *',"type":"search","country":_vm.allowedCountries,"dataId":"input_pdf_summary_address_google"},on:{"placechanged":_vm.getAddressData,"inputChange":_vm.onGoogleInputChange}}),(_vm.checkErrors && _vm.isGoogleAddressInvalid)?_c('error-message',[_vm._v(" "+_vm._s(_vm.translate('field_required'))+" ")]):_vm._e()],1)],1):_vm._e(),(!_vm.isGoogleAddressRequired)?_c('row-wrapper',{attrs:{"numCols":2}},[_c('input-component',{attrs:{"placeholder":_vm.translate('street') +
          ` ${
            _vm.companySettings.calculator_configuration
              .request_address_compulsory === 'on'
              ? '*'
              : ''
          }`,"value":_vm.form.route,"isError":_vm.checkErrors &&
          _vm.companySettings.calculator_configuration
            .request_address_compulsory === 'on' &&
          !_vm.form.route.length,"dataId":"input_pdf_summary_street"},on:{"input-change":function($event){return _vm.onInputChange({ value: $event, type: 'route' })}}}),_c('input-component',{attrs:{"placeholder":_vm.translate('house_number') +
          ` ${
            _vm.companySettings.calculator_configuration
              .request_address_compulsory === 'on'
              ? '*'
              : ''
          }`,"value":_vm.form.street_number,"isError":_vm.checkErrors &&
          _vm.companySettings.calculator_configuration
            .request_address_compulsory === 'on' &&
          !_vm.form.street_number.length,"dataId":"input_pdf_summary_house_no"},on:{"input-change":function($event){return _vm.onInputChange({ value: $event, type: 'street_number' })}}})],1):_vm._e(),(!_vm.isGoogleAddressRequired)?_c('row-wrapper',{attrs:{"numCols":2}},[_c('input-component',{attrs:{"placeholder":_vm.translate('postal_code') +
          ` ${
            _vm.companySettings.calculator_configuration
              .request_address_compulsory === 'on'
              ? '*'
              : ''
          }`,"value":_vm.form.postal_code,"isError":_vm.checkErrors &&
          _vm.companySettings.calculator_configuration
            .request_address_compulsory === 'on' &&
          !_vm.form.postal_code.length,"dataId":"input_pdf_summary_postal_code"},on:{"input-change":function($event){return _vm.onInputChange({ value: $event, type: 'postal_code' })}}}),_c('input-component',{attrs:{"placeholder":_vm.translate('city') +
          ` ${
            _vm.companySettings.calculator_configuration
              .request_address_compulsory === 'on'
              ? '*'
              : ''
          }`,"value":_vm.form.locality,"isError":_vm.checkErrors &&
          _vm.companySettings.calculator_configuration
            .request_address_compulsory === 'on' &&
          !_vm.form.locality.length,"dataId":"input_pdf_summary_city"},on:{"input-change":function($event){return _vm.onInputChange({ value: $event, type: 'locality' })}}})],1):_vm._e(),_c('row-wrapper',{attrs:{"numCols":2}},[_c('input-component',{attrs:{"placeholder":_vm.translate('email_address') +
          ` ${
            _vm.companySettings.calculator_configuration
              .request_email_compulsory === 'on'
              ? '*'
              : ''
          }`,"value":_vm.form.email_address,"isError":_vm.checkErrors && _vm.displayEmailError,"errorMessage":_vm.emailErrorMessage,"dataId":"input_pdf_summary_email"},on:{"input-change":function($event){return _vm.onInputChange({ value: $event, type: 'email_address' })}}}),_c('input-component',{attrs:{"placeholder":_vm.translate('phone') +
          ` ${
            _vm.companySettings.calculator_configuration
              .request_phone_number_compulsory === 'on'
              ? '*'
              : ''
          }`,"value":_vm.form.phone_number,"isError":_vm.checkErrors &&
          _vm.companySettings.calculator_configuration
            .request_phone_number_compulsory === 'on' &&
          !_vm.form.phone_number.length,"dataId":"input_pdf_summary_phone"},on:{"input-change":function($event){return _vm.onInputChange({ value: $event, type: 'phone_number' })}}})],1),(_vm.getSystems().length)?_c('section-title',{attrs:{"data-id":"label_pdf_summary_im_also_interested"}},[_vm._v(" "+_vm._s(_vm.translate('interests'))+" ")]):_vm._e(),(_vm.getSystems().length)?_c('toggle-container',_vm._l((_vm.getSystems()),function(item){return _c('toggle',{key:item.value,attrs:{"isChecked":_vm.form[item.value],"label":item.label,"dataId":`switch_pdf_summary_technology_${item.value}`},on:{"on-toggle-change":function($event){return _vm.onInputChange({ value: $event, type: item.value })}}})}),1):_vm._e(),_c('text-area-component',{attrs:{"placeholder":_vm.translate('comments'),"value":_vm.form.note,"dataId":"input_pdf_summary_your_message"},on:{"input-change":function($event){return _vm.onInputChange({ value: $event, type: 'note' })}}}),_vm._l((_vm.customToggles),function(toggle,index){return _c('terms-container',{key:index},[_c('toggle',{attrs:{"isChecked":toggle.answers.boolean_field.value,"label":toggle.caption + (toggle.required ? ' *' : '')},on:{"on-toggle-change":function($event){toggle.answers.boolean_field.value =
            !toggle.answers.boolean_field.value}}})],1)}),(
        _vm.companySettings.calculator_configuration
          .request_data_acceptance_checkbox_show === 'show'
      )?_c('terms-container',[_c('toggle',{attrs:{"dataId":"switch_pdf_summary_data_processing_consent","isChecked":_vm.termsAccepted,"label":_vm.translate('allow_personal_details')},on:{"on-toggle-change":function($event){return _vm.toggleTerms($event)}}})],1):_vm._e(),(
        _vm.companySettings.calculator_configuration
          .request_newsletter_checkbox_show === 'show'
      )?_c('terms-container',[_c('toggle',{attrs:{"dataId":"switch_pdf_summary_newsletter","isChecked":_vm.form.interest_newsletter,"label":_vm.translate('newsletter_subscribe')},on:{"on-toggle-change":function($event){return _vm.toggleNewsletter($event)}}})],1):_vm._e(),_c('button-container',{nativeOn:{"mouseenter":function($event){return _vm.onSubmitHover(true)},"mouseleave":function($event){return _vm.onSubmitHover(false)}}},[(_vm.showDataText && _vm.termsErrorMessage)?_c('data-text',[_vm._v(" "+_vm._s(_vm.termsErrorMessage)+" ")]):_vm._e(),_c('button-component',{attrs:{"type":"primary","dataId":"button_pdf_summary_send","text":_vm.translate('send_button'),"disabled":Boolean(_vm.termsErrorMessage)},nativeOn:{"click":function($event){return _vm.onSubmit()}}}),(_vm.privacyLink)?_c('link-text',{attrs:{"href":_vm.privacyLink,"target":"blank"}},[_vm._v(_vm._s(_vm.translate('data_protection')))]):_vm._e()],1),(_vm.translate('request_bottomtext') !== 'request_bottomtext')?_c('bottomtext-container',{domProps:{"innerHTML":_vm._s(_vm.translate('request_bottomtext'))}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }