<template>
  <wrapper>
    <item-container v-for="(item, index) in options" :key="index">
      <image-container
        @click.native="$emit('on-click-image', index)"
        :isSelected="selectedImage === index"
      >
        <item-image :src="item.img_url" />
        <img
          class="search-icn"
          :src="require('@/assets/icons/search_icon.png')"
        />
      </image-container>
      <info-container>
        <info-title>{{ item.brand_name }} - {{ item.model }}</info-title>
        <info-subtitle
          >{{ translate('quantity') }}: {{ item.quantity }}</info-subtitle
        >
      </info-container>
    </item-container>
  </wrapper>
</template>

<script>
import styled from 'vue-styled-components'

const Wrapper = styled.div`
  width: 100%;
`

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumGray};
  padding: 10px 0;

  &:first-child {
    border-top: 1px solid ${(props) => props.theme.colors.mediumGray};
  }
`

const ItemImage = styled.img`
  height: 60px;
`

const imageAttrs = { isSelected: Boolean }
const ImageContainer = styled('div', imageAttrs)`
  width: 70px;
  height: 70px;
  border-radius: 4px;
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-items: center;
  position: relative;
  background: ${(props) =>
    props.isSelected ? 'rgba(0, 0, 0, 0.4)' : 'inherit'};

  .search-icn {
    display: ${(props) => (props.isSelected ? 'block' : 'none')};
    position: absolute;
    top: 30%;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.4);

    .search-icn {
      display: block;
      position: absolute;
      top: 30%;
    }
  }
`

const InfoContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  align-content: center;
`

const InfoTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
`

const InfoSubtitle = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 14px;
`

export default {
  name: 'chosen-component',
  components: {
    Wrapper,
    ItemContainer,
    ItemImage,
    InfoContainer,
    InfoTitle,
    InfoSubtitle,
    ImageContainer
  },
  props: {
    options: {
      required: true
    },
    selectedImage: {
      required: false,
      default: null
    }
  }
}
</script>
