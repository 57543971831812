import apiCall from '@/assets/helpers/apiCall'

const state = {
  companySettings: null,
  settingsLoading: true,
  translationsLoading: true,
  showAcceptCookies: false,
  allowCookies: false,
  privacyLink: null,
  companyToken: null,
  showMobileFooter: false,
  isEmbedded: false // true = in an iframe
}

const getters = {
  getCompanySettings(state) {
    return state.companySettings
  },
  getSettingsLoading(state) {
    return state.settingsLoading
  },
  getShowAcceptCookies(state) {
    return state.showAcceptCookies
  },
  getAllowCookies(state) {
    return state.allowCookies
  },
  getTranslationsLoading(state) {
    return state.translationsLoading
  },
  getCompanyToken(state) {
    return state.companyToken
  },
  getPrivacyLink(state) {
    return state.privacyLink
  },
  getShowMobileFooter(state) {
    return state.showMobileFooter
  },
  getIsEmbedded(state) {
    return state.isEmbedded
  }
}

const mutations = {
  setCompanySettings(state, payload) {
    state.companySettings = payload
  },
  setSettingsLoading(state, payload) {
    state.settingsLoading = payload
  },
  setShowAcceptCookies(state, payload) {
    state.showAcceptCookies = payload
  },
  setAllowCookies(state, payload) {
    state.allowCookies = payload
  },
  setTranslationsLoading(state, payload) {
    state.translationsLoading = payload
  },
  setCompanyToken(state, payload) {
    state.companyToken = payload
  },
  setPrivacyLink(state, payload) {
    state.privacyLink = payload
  },
  setShowMobileFooter(state, payload) {
    state.showMobileFooter = payload
  },
  setIsEmbedded(state, payload) {
    state.isEmbedded = payload
  }
}

const actions = {
  getCompanySettings({ commit, state, dispatch }) {
    commit('setSettingsLoading', true)
    apiCall
      .get(`configurator/configurator_settings/${state.companyToken}/`)
      .then((res) => {
        const storageLang = localStorage.getItem('lang')
        const languages = res.data.language
        const urlParams = new URLSearchParams(window.location.search)
        let urlLang = urlParams.get('lang')

        if (urlLang) {
          localStorage.setItem('lang', urlLang)
          const selectedLang = languages.filter((lang) => {
            return lang.language === urlLang
          })

          if (selectedLang.length) {
            localStorage.setItem('currency', selectedLang[0].currency)
          }
        } else if (
          !storageLang ||
          !languages.some((e) => e.language === storageLang)
        ) {
          const defaultLang = languages.filter((x) => {
            return x.default === true
          })
          // This needs to be tested
          let langToSet =
            defaultLang && defaultLang.length ? defaultLang[0] : languages[0]
          localStorage.setItem('lang', langToSet.language)
          localStorage.setItem('currency', langToSet.currency)
        }

        commit('setCompanySettings', res.data)
        dispatch('getCreateVisitor')
      })
      .catch(() => {
        commit('setSettingsLoading', false)
        dispatch('addNotification', {
          type: 'error',
          text: 'error_occurred'
        })
      })
  },
  getCreateVisitor({ commit, dispatch }) {
    apiCall
      .post(`configurator/create_visitor/${state.companyToken}/`)
      .then((res) => {
        sessionStorage.setItem(
          'visitor_uuid',
          res.data.configurator_visitor_uuid
        )
        commit('setSettingsLoading', false)
        const session = sessionStorage.getItem('session_uuid')
        if (session) {
          dispatch('getStartSession')
        }
      })
      .catch(() => {
        commit('setSettingsLoading', false)
        dispatch('addNotification', {
          type: 'error',
          text: 'error_occurred'
        })
      })
  },
  getTranslations({ commit, dispatch }) {
    commit('setTranslationsLoading', true)
    return apiCall
      .get(`configurator/configurator_translations/${state.companyToken}/`)
      .then((res) => {
        const data = res.data
        localStorage.setItem('translations', JSON.stringify(data))
        commit('setTranslationsLoading', false)
        return 'success'
      })
      .catch(() => {
        commit('setTranslationsLoading', false)
        dispatch('addNotification', {
          type: 'error',
          text: 'error_occurred'
        })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
