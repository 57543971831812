import axios from 'axios'

const apiCall = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: { 'Accept-Language': localStorage.getItem('lang') }
})

apiCall.interceptors.request.use((config) => {
  config.headers['Accept-Language'] = localStorage.getItem('lang')
  return config
})

export default apiCall
