import apiCall from '@/assets/helpers/apiCall'

const state = {
  questionLoading: false,
  summaryLoading: false,
  activeQuestion: null,
  summaryInfo: null,
  pdfRequestLoading: false,
  selectedAnswers: [],
  sessionStatus: null,
  customQuestionsRequest: [],
  pdfRequestError: false
}

const getters = {
  getQuestionLoading(state) {
    return state.questionLoading
  },
  getActiveQuestion(state) {
    return state.activeQuestion
  },
  getSummaryInfo(state) {
    return state.summaryInfo
  },
  getSummaryLoading(state) {
    return state.summaryLoading
  },
  getPdfRequestLoading(state) {
    return state.pdfRequestLoading
  },
  getSelectedAnswers(state) {
    return state.selectedAnswers
  },
  getSessionStatus(state) {
    return state.sessionStatus
  },
  getCustomQuestionsRequest(state) {
    return state.customQuestionsRequest
  },
  getPdfRequestError(state) {
    return state.pdfRequestError
  }
}

const mutations = {
  setQuestionLoading(state, payload) {
    state.questionLoading = payload
  },
  setActiveQuestion(state, payload) {
    state.activeQuestion = payload
  },
  setSummaryInfo(state, payload) {
    state.summaryInfo = payload
  },
  setSummaryLoading(state, payload) {
    state.summaryLoading = payload
  },
  setPdfRequestLoading(state, payload) {
    state.pdfRequestLoading = payload
  },
  setSelectedAnswers(state, payload) {
    state.selectedAnswers = payload
  },
  setSessionStatus(state, payload) {
    state.sessionStatus = payload
  },
  setCustomQuestionsRequest(state, value) {
    state.customQuestionsRequest = value
  },
  setPdfRequestError(state, payload) {
    state.pdfRequestError = payload
  }
}

const actions = {
  postStartSession({ commit, dispatch }) {
    // This is what is triggered after the "Start" button is clicked
    commit('setSummaryInfo', null)
    commit('setQuestionLoading', true)

    const visitor = sessionStorage.getItem('visitor_uuid')

    const body = {
      language: localStorage.getItem('lang'),
      currency: localStorage.getItem('currency')
    }

    apiCall
      .post(`configurator/${visitor}/session/`, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        commit('setActiveQuestion', res.data)
        commit('setSessionStatus', res.data.session_status)
        commit('setSelectedAnswers', res.data.answered_questions)
        sessionStorage.setItem('session_uuid', res.data.session_uuid)
        commit('setQuestionLoading', false)
      })
      .catch(() => {
        commit('setQuestionLoading', false)
        dispatch('addNotification', {
          type: 'error',
          text: 'error_occurred'
        })
      })
  },
  getStartSession({ commit, dispatch }) {
    // This is what is triggered after the "Start" button is clicked
    commit('setSummaryInfo', null)
    commit('setQuestionLoading', true)
    const visitor = sessionStorage.getItem('visitor_uuid')
    const session = sessionStorage.getItem('session_uuid')

    apiCall
      .get(`configurator/${visitor}/session/${session}/`)
      .then((res) => {
        const data = res.data
        if (
          data.session_status === 'completed' ||
          data.session_status === 'requested'
        ) {
          commit('setSessionStatus', data.session_status)
          commit('setQuestionLoading', false)
          dispatch('getSummary')
          commit('setActiveQuestion', null)
          commit('setSelectedAnswers', data.answered_questions)
        } else {
          commit('setActiveQuestion', data)
          commit('setSessionStatus', data.session_status)
          sessionStorage.setItem('session_uuid', data.session_uuid)
          commit('setQuestionLoading', false)
          commit('setSelectedAnswers', data.answered_questions)
        }
      })
      .catch(() => {
        commit('setQuestionLoading', false)
        dispatch('addNotification', {
          type: 'error',
          text: 'error_occurred'
        })
      })
  },
  putSession({ commit, dispatch }, { data }) {
    // This is what is triggered after the language is changed
    commit('setSummaryInfo', null)
    commit('setQuestionLoading', true)
    const visitor = sessionStorage.getItem('visitor_uuid')
    const session = sessionStorage.getItem('session_uuid')

    apiCall
      .put(`configurator/${visitor}/session/${session}/`, data)
      .then((res) => {
        const data = res.data
        if (
          data.session_status === 'completed' ||
          data.session_status === 'requested'
        ) {
          commit('setSessionStatus', data.session_status)
          commit('setQuestionLoading', false)
          dispatch('getSummary')
          commit('setActiveQuestion', null)
          commit('setSelectedAnswers', data.answered_questions)
        } else {
          commit('setActiveQuestion', data)
          commit('setSessionStatus', data.session_status)
          sessionStorage.setItem('session_uuid', data.session_uuid)
          commit('setQuestionLoading', false)
          commit('setSelectedAnswers', data.answered_questions)
        }
      })
      .catch(() => {
        commit('setQuestionLoading', false)
        dispatch('addNotification', {
          type: 'error',
          text: 'error_occurred'
        })
      })
  },
  postQuestionAnswer({ commit, dispatch }, { data }) {
    commit('setQuestionLoading', true)
    commit('setSummaryInfo', null)
    const sessionId = sessionStorage.getItem('session_uuid')
    return apiCall
      .post(`configurator/session/${sessionId}/answer/`, data)
      .then((res) => {
        const data = res.data
        if (data.session_status === 'completed') {
          dispatch('getSummary')
          commit('setActiveQuestion', null)
        } else {
          commit('setActiveQuestion', data)
          sessionStorage.setItem('session_uuid', data.session_uuid)
        }
        commit('setSessionStatus', data.session_status)
        commit('setSelectedAnswers', data.answered_questions)
        commit('setQuestionLoading', false)
        return 'success'
      })
      .catch(() => {
        commit('setQuestionLoading', false)
        dispatch('addNotification', {
          type: 'error',
          text: 'error_occurred'
        })
        return 'error'
      })
  },
  postModifyAnswer({ commit, dispatch }, { data }) {
    commit('setQuestionLoading', true)
    const sessionId = sessionStorage.getItem('session_uuid')
    return apiCall
      .post(`configurator/session/${sessionId}/modify/`, data)
      .then((res) => {
        const data = res.data
        commit('setActiveQuestion', data)
        commit('setSelectedAnswers', data.answered_questions)
        commit('setQuestionLoading', false)
        return 'success'
      })
      .catch(() => {
        commit('setQuestionLoading', false)
        dispatch('addNotification', {
          type: 'error',
          text: 'error_occurred'
        })
        return 'error'
      })
  },
  getSummary({ commit, dispatch }) {
    commit('setSummaryLoading', true)
    const sessionId = sessionStorage.getItem('session_uuid')
    const summaryRequests = [
      apiCall.get(`configurator/session/${sessionId}/summary/`)
    ]
    if (
      this.state.authModule.companySettings.calculator_configuration
        .customized_questions_enabled
    ) {
      summaryRequests.push(
        apiCall.get(
          `customized_questions/configurator/question/${this.state.authModule.companyToken}/`
        )
      )
    }
    Promise.all(summaryRequests)
      .then((res) => {
        commit('setActiveQuestion', null)
        commit('setSummaryInfo', res[0].data)
        if (res[1]) {
          res[1].data.forEach((question) => {
            question.answers.boolean_field.value =
              question.answers.boolean_field.default
          })
          commit('setCustomQuestionsRequest', res[1].data)
        }
        commit('setSummaryLoading', false)
      })
      .catch(() => {
        commit('setActiveQuestion', null)
        commit('setSummaryLoading', false)
        commit('setSummaryInfo', null)
        dispatch('addNotification', {
          type: 'error',
          text: 'error_occurred'
        })
      })
  },
  resetCalculator({ commit, dispatch }) {
    commit('setQuestionLoading', true)
    commit('setSummaryInfo', null)
    commit('setActiveQuestion', null)
    sessionStorage.setItem('requestSubmitted', 'false')
    commit('setPdfRequestError', false)
    sessionStorage.removeItem('visitor_uuid')
    sessionStorage.removeItem('session_uuid')

    setTimeout(() => {
      commit('setQuestionLoading', false)
      dispatch('getCompanySettings')
    }, 500)
  },
  postPdfRequest({ commit, dispatch }, { form, customToggles }) {
    commit('setPdfRequestLoading', true)
    const urlParams = new URLSearchParams(document.location.search)
    const requestParameters = {}
    urlParams.forEach((value, key) => {
      if (
        key === 'external_source' ||
        key === 'utm_source' ||
        key === 'utm_medium' ||
        key === 'utm_campaign' ||
        key === 'utm_term' ||
        key === 'utm_content'
      ) {
        if (key === 'utm_source' || key === 'external_source') {
          form['utm_source'] = value
        } else {
          form[key] = value
        }
      } else {
        if (key !== 'lang' && key !== 'profile_id') {
          requestParameters[key] = value
        }
      }
    })
    form = { ...form, request_parameters: requestParameters }
    const sessionId = sessionStorage.getItem('session_uuid')
    const postArray = [apiCall.post(`configurator/${sessionId}/request/`, form)]

    const customTogglesAnswers = []
    if (customToggles.length) {
      customToggles.forEach((toggle) => {
        customTogglesAnswers.push({
          question_uuid: toggle.question_uuid,
          answer_uuid: toggle.answers.boolean_field.answer_uuid,
          answer_value: toggle.answers.boolean_field.value
        })
      })
      postArray.push(
        apiCall.post(
          `customized_questions/configurator/session_answer/${sessionId}/`,
          customTogglesAnswers
        )
      )
    }

    Promise.all(postArray)
      .then(() => {
        commit('setPdfRequestLoading', false)
        sessionStorage.setItem('requestSubmitted', 'true')
        commit('setPdfRequestError', false)
      })
      .catch(() => {
        commit('setPdfRequestLoading', false)
        sessionStorage.setItem('requestSubmitted', 'false')
        commit('setPdfRequestError', true)
        dispatch('addNotification', {
          type: 'error',
          text: 'error_occurred'
        })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
