<template>
  <desktop-container v-if="$screen.width > 950">
    <link-container>
      <link-item
        v-for="item in links"
        :key="item.display_text"
        :href="item.url"
        target="_blank"
      >
        {{ item.display_text }}
      </link-item>
      <translation-item @click="toggleTranslations">
        <div>{{ getSelectedLanguage() }}</div>
        <arrow-down
          :src="require('@/assets/icons/arrow_down.png')"
          :class="{ arrowUp: languagesOpen, arrowDown: !languagesOpen }"
        />
        <translation-list-container v-if="languagesOpen">
          <translation-list-item
            v-for="item in allLanguages"
            :key="item.value"
            @click="onLanguageChange(item.value)"
          >
            {{ item.name }}
          </translation-list-item>
        </translation-list-container>
      </translation-item>
    </link-container>
  </desktop-container>
  <mobile-container v-else>
    <mobile-wrapper v-if="!languagesOpen">
      <mobile-item>
        <mobile-text @click="toggleTranslations">{{
          translate('language')
        }}</mobile-text>
        <mobile-icon
          :src="require('@/assets/icons/triangle_right_white.png')"
        />
      </mobile-item>
      <mobile-item v-for="item in links" :key="item.display_text">
        <mobile-link :href="item.url" target="_blank">{{
          item.display_text
        }}</mobile-link>
      </mobile-item>
    </mobile-wrapper>
    <mobile-wrapper v-if="languagesOpen">
      <mobile-item
        v-for="item in allLanguages"
        :key="item.value"
        @click="onLanguageChange(item.value)"
      >
        <mobile-text @click="onLanguageChange(item.value)">{{
          item.name
        }}</mobile-text>
        <mobile-icon
          v-if="selectedLanguage === item.value"
          :src="require('@/assets/icons/check_mark_yellow.png')"
        />
      </mobile-item>
    </mobile-wrapper>
  </mobile-container>
</template>

<script>
import styled from 'vue-styled-components'
import { mapGetters, mapMutations } from 'vuex'
import { mapActions } from 'vuex'

const DesktopContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 1200px;
  background: #fff;
  padding: 12px 0;
  font-size: 14px;
  z-index: 9999;
`

const MobileContainer = styled.div`
  background-color: ${(props) => props.theme.colors.black};
  padding-bottom: 30px;
  z-index: 2;
  width: 100%;
`

const MobileWrapper = styled.div`
  display: grid;
`

const MobileItem = styled.div`
  color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding: 20px 0;
  margin: 0 16px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px 16px;
    margin: 0;
  }
`

const MobileIcon = styled.img``

const MobileText = styled.div``

const MobileLink = styled.a`
  color: ${(props) => props.theme.colors.white};
  text-decoration: none;

  &:visited {
    color: ${(props) => props.theme.colors.white};
  }
`

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
`

const LinkItem = styled.a`
  padding: 0 20px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};
  border-right: 1px solid ${(props) => props.theme.colors.mediumGray};

  &:visited {
    color: ${(props) => props.theme.colors.black};
  }
`

const TranslationItem = styled.div`
  padding: 0 20px;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 10px;
  align-items: center;
  position: relative;

  .arrowUp {
    transform: rotate(180deg);
    transition: transform 150ms ease;
  }

  .arrowDown {
    transform: rotate(0);
    transition: transform 150ms ease;
  }
`

const TranslationListContainer = styled.div`
  background: #fff;
  position: absolute;
  min-width: max-content;
  bottom: 30px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border-radius: 4px;
`

const TranslationListItem = styled.div`
  padding: 10px 30px;

  &:hover {
    background-color: ${(props) => props.theme.colors.lightGray};
    border-radius: 4px;
  }
`

const ArrowDown = styled.img``

export default {
  name: 'footer-component',
  components: {
    DesktopContainer,
    MobileContainer,
    LinkItem,
    TranslationItem,
    LinkContainer,
    ArrowDown,
    TranslationListContainer,
    TranslationListItem,
    MobileItem,
    MobileIcon,
    MobileText,
    MobileLink,
    MobileWrapper
  },
  data() {
    return {
      links: [],
      languagesOpen: false,
      selectedLanguage: null,
      allLanguages: []
    }
  },
  methods: {
    ...mapActions({
      getTranslations: 'getTranslations',
      putSession: 'putSession',
      getCompanySettings: 'getCompanySettings'
    }),
    ...mapMutations({
      setPrivacyLink: 'setPrivacyLink'
    }),
    toggleTranslations() {
      this.languagesOpen = !this.languagesOpen

      if (this.languagesOpen) {
        document.addEventListener('click', this.clickOutside)
      } else {
        document.removeEventListener('click', this.clickOutside)
      }
    },
    getSelectedLanguage() {
      const selected = this.allLanguages.filter(
        (item) => item.value === this.selectedLanguage
      )
      return selected.length
        ? selected[0].name
        : this.companySettings.language[0].language
    },
    onLanguageChange(value) {
      if (value === this.selectedLanguage) {
        return
      }
      localStorage.setItem('lang', value)
      const urlParams = new URLSearchParams(window.location.search)
      let urlLang = urlParams.get('lang')
      if (urlLang) {
        // if there is a language in the URL, update it to the newly selected language
        urlParams.set('lang', value)
        window.location.search = urlParams.toString()
      }
      this.selectedLanguage = value
      this.updateFooterLinks()
      this.getTranslations().then((res) => {
        if (res === 'success') {
          // After changing the language, we need to make these calls again to get new information
          this.getCompanySettings()
          const session = sessionStorage.getItem('session_uuid')
          const data = {
            language: value
          }
          if (session) {
            this.putSession({ data })
          }
        }
      })

      setTimeout(() => {
        // This will trigger a close on mobile
        this.$emit('on-language-selected')
      }, 700)
    },
    clickOutside(event) {
      if (this.$el.contains(event.target) || this.$screen.width < 951) {
        return
      }
      this.toggleTranslations()
    },
    updateFooterLinks() {
      const filterLang = this.companySettings.language.filter((item) => {
        return item.language === this.selectedLanguage
      })
      const currency = filterLang.length ? filterLang[0].currency : null
      if (currency) {
        localStorage.setItem('currency', currency)
      }
      this.links = filterLang.length ? filterLang[0].footer_links : []
      const privacyLink = filterLang.length
        ? filterLang[0].request_privacy_link
        : null
      this.setPrivacyLink(privacyLink)
    }
  },
  computed: {
    ...mapGetters({
      companySettings: 'getCompanySettings'
    })
  },
  created() {
    const storageLang = localStorage.getItem('lang')
    const languages = this.companySettings.language
    if (!storageLang || !languages.some((e) => e.language === storageLang)) {
      localStorage.setItem('lang', languages[0].language)
      localStorage.setItem('currency', languages[0].currency)
    }
    this.selectedLanguage = storageLang ? storageLang : languages[0].language
    languages.map((item) => {
      this.allLanguages.push({ name: item.text, value: item.language })
    })
    this.updateFooterLinks()
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside)
  }
}
</script>
