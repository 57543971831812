<template>
  <component-wrapper>
    <desktop-wrapper v-if="$screen.width > 768">
      <desktop-icon :isActive="showInfo" @click="toggleShowInfo()">
        <svg
          class="img-icon"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="img-icon"
            d="M20.4853 3.51469C18.2188 1.24823 15.2053 0 12 0C8.79469 0 5.78123 1.24823 3.51469 3.51469C1.24823 5.78123 0 8.79469 0 12C0 15.2053 1.24823 18.2188 3.51469 20.4853C5.78123 22.7518 8.79469 24 12 24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79469 22.7518 5.78123 20.4853 3.51469ZM12 3.28125C13.4216 3.28125 14.5781 4.4378 14.5781 5.85938C14.5781 7.28095 13.4216 8.4375 12 8.4375C10.5784 8.4375 9.42188 7.28095 9.42188 5.85938C9.42188 4.4378 10.5784 3.28125 12 3.28125ZM15.2812 19.6875H8.71875V18.2812H10.125V11.9167H8.71875V10.5104H13.875V18.2812H15.2812V19.6875Z"
            fill="#D5D5D5"
          />
        </svg>
      </desktop-icon>
      <desktop-overlay v-if="showInfo" v-html="text" />
    </desktop-wrapper>
    <div v-if="$screen.width < 769 && text.length">
      <mobile-icon @click="toggleShowInfo()" :isEmbedded="getIsEmbedded">
        <svg
          class="img-icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="img-icon"
            d="M20.4853 3.51469C18.2188 1.24823 15.2053 0 12 0C8.79469 0 5.78123 1.24823 3.51469 3.51469C1.24823 5.78123 0 8.79469 0 12C0 15.2053 1.24823 18.2188 3.51469 20.4853C5.78123 22.7518 8.79469 24 12 24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79469 22.7518 5.78123 20.4853 3.51469ZM12 3.28125C13.4216 3.28125 14.5781 4.4378 14.5781 5.85938C14.5781 7.28095 13.4216 8.4375 12 8.4375C10.5784 8.4375 9.42188 7.28095 9.42188 5.85938C9.42188 4.4378 10.5784 3.28125 12 3.28125ZM15.2812 19.6875H8.71875V18.2812H10.125V11.9167H8.71875V10.5104H13.875V18.2812H15.2812V19.6875Z"
            fill="#D5D5D5"
          />
        </svg>
      </mobile-icon>
    </div>
    <overlay-wrapper v-if="showInfo && $screen.width < 769">
      <mobile-overlay v-html="text" />
      <close-button @click.native="toggleShowInfo()" />
    </overlay-wrapper>
  </component-wrapper>
</template>

<script>
//To use:
// <info-component
//     text="Veritatis et quasi architecto beatae vitae"
// />

import styled from 'vue-styled-components'
import CloseButton from '@/components/reusable-components/CloseButton'
import { mapGetters } from 'vuex'

const DesktopWrapper = styled.div`
  position: relative;
`

const DesktopOverlay = styled.div`
  position: absolute;
  top: 42px;
  right: -10px;
  background: #fff;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  padding: 16px;
  width: max-content;
  max-width: 400px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  z-index: 99;

  :before {
    content: '';
    background-color: #fff;
    position: absolute;
    top: 2px;
    right: -12px;
    height: 8px;
    width: 8px;
    border: 1px solid ${(props) => props.theme.colors.secondary};
    border-width: 1px 0 0 1px;
    transform-origin: center center;
    transform: translate(-2em, -0.5em) rotate(45deg);
  }

  img {
    max-width: 120px;
    max-height: 120px;
  }

  & > div {
    span {
      margin: 0 10px;
    }

    img {
      padding: 5px;
      max-width: 120px;
      max-height: 120px;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const MobileOverlay = styled.div`
  display: flex;
  justify-content: center;
  background: #fff;
  max-width: 350px;
  font-weight: 400;
  z-index: 99;
  font-size: 15px;
  padding: 96px 16px 0;

  img {
    max-width: 120px;
    max-height: 120px;
  }
  & > div {
    span {
      margin: 0 10px;
    }

    img {
      padding: 5px;
      max-width: 120px;
      max-height: 120px;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const OverlayWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 50px;
  justify-items: center;
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
  background: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
`

const embeddedAttrs = { isEmbedded: Boolean }
const MobileIcon = styled('div', embeddedAttrs)`
  cursor: pointer;
  position: absolute;
  top: ${(props) => (props.isEmbedded ? '30px' : '80px')};
  right: 20px;
`

const iconAttrs = { isActive: Boolean }
const DesktopIcon = styled('div', iconAttrs)`
  cursor: pointer;

  .img-icon {
    fill: ${(props) =>
      props.isActive
        ? props.theme.colors.secondary
        : props.theme.colors.mediumGray};
  }
`

const ComponentWrapper = styled.div`
  display: inline-block;
`

export default {
  name: 'info-component',
  components: {
    DesktopWrapper,
    DesktopOverlay,
    MobileOverlay,
    OverlayWrapper,
    ComponentWrapper,
    CloseButton,
    MobileIcon,
    DesktopIcon
  },
  props: {
    text: {
      required: true
    }
  },
  methods: {
    toggleShowInfo() {
      this.showInfo = !this.showInfo

      if (this.showInfo) {
        document.addEventListener('click', this.clickOutside)
      } else {
        document.removeEventListener('click', this.clickOutside)
      }
    },
    clickOutside(event) {
      if (this.$el.contains(event.target)) {
        return
      }
      this.toggleShowInfo()
    }
  },
  computed: {
    ...mapGetters({
      getIsEmbedded: 'getIsEmbedded'
    })
  },
  data() {
    return {
      showInfo: false
    }
  }
}
</script>
