<template>
  <wrapper>
    <desktop-container v-if="$screen.width > 950">
      <text-container>
        <cookie-icon :src="require('@/assets/icons/cookie_white.png')" />
        <div>
          {{ translate('cookie_message') }}
        </div>
      </text-container>
      <button-container>
        <button-component
          type="white"
          :text="translate('cookie_accept')"
          size="small"
          @click.native="setCookie(true)"
        />
        <button-component
          type="black"
          :text="translate('cookie_reject')"
          size="small"
          @click.native="setCookie(false)"
        />
      </button-container>
    </desktop-container>
    <mobile-container v-else>
      <translate-icon
        :src="require('@/assets/icons/language_icon.svg')"
        @click="setShowMobileFooter(true)"
      />
      <close-container>
        <close-button color="#fff" @click.native="setCookie(false)" />
      </close-container>
      <cookie-icon :src="require('@/assets/icons/cookie_yellow.png')" />
      <text-container>
        {{ translate('cookie_message') }}
      </text-container>
      <button-component
        type="white"
        :text="translate('cookie_accept')"
        @click.native="setCookie(true)"
      />
    </mobile-container>
  </wrapper>
</template>

<script>
import styled from 'vue-styled-components'
import { mapMutations } from 'vuex'
import ButtonComponent from '@/components/reusable-components/ButtonComponent'
import CloseButton from '@/components/reusable-components/CloseButton'

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.black};
  width: 100%;
  padding: 9px 0;
  position: fixed;
  z-index: 9999;
  bottom: 40px;
  left: 0;

  @media (max-width: ${(props) => props.theme.screen.tabletLarge}) {
    padding: 42px 0 9px;
    bottom: 0;
  }
`

const DesktopContainer = styled.div`
  padding: 0 40px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 12px;
`

const MobileContainer = styled.div`
  position: relative;
  padding: 0 16px 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  justify-items: center;
  align-items: center;
`

const TranslateIcon = styled.img`
  position: absolute;
  top: -30px;
  left: 14px;
  cursor: pointer;
`

const TextContainer = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  justify-self: flex-start;
  align-self: center;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  align-items: center;

  @media (max-width: ${(props) => props.theme.screen.tabletLarge}) {
    grid-template-columns: 1fr;
    justify-items: center;
    font-size: 20px;
    text-align: center;
  }
`

const CookieIcon = styled.img``

const ButtonContainer = styled.div`
  display: inline-flex;
  justify-self: flex-end;
  grid-gap: 16px;
`

const CloseContainer = styled.div`
  position: absolute;
  top: -30px;
  right: 14px;
`

export default {
  name: 'accept-cookies',
  components: {
    Wrapper,
    DesktopContainer,
    MobileContainer,
    TextContainer,
    ButtonContainer,
    ButtonComponent,
    CookieIcon,
    CloseButton,
    CloseContainer,
    TranslateIcon
  },
  methods: {
    ...mapMutations({
      setShowAcceptCookies: 'setShowAcceptCookies',
      setAllowCookies: 'setAllowCookies',
      setShowMobileFooter: 'setShowMobileFooter'
    }),
    setCookie(value) {
      this.$cookies.set('allowCookies', value, 0)
      this.setAllowCookies(value)
      this.setShowAcceptCookies(false) // we want to hide the cookie message after a seleciton is made
    }
  }
}
</script>
