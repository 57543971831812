<template>
  <button-container
    :disabled="disabled"
    :backgroundColor="backgroundColor"
    :type="type"
    :minWidth="minWidth"
    :maxWidth="maxWidth"
    :fontSize="fontSize"
    :hasIcon="!!iconLeading || !!iconTrailing"
    :size="size"
    :fontStyle="computedFontStyle"
    :fontWeight="computedFontWeight"
    :data-id="dataId"
  >
    <leading-icon v-if="iconLeading" :src="iconLeading" />
    {{ text }}
    <trailing-icon v-if="iconTrailing" :src="iconTrailing" />
  </button-container>
</template>

<script>
//To Use:
// <button-component
// :disabled="false"
// :backgroundColor="#888" //this has priority over 'type'
// :type="black" // primary, secondary, white, tertiary
// :minWidth="150px"
// :maxWidth="100%"
// :fontSize="16px"
// text="My Button Text" //required
// @click.native="(your function here)"
// :iconLeading="require('@/assets/icons/pdf_icon.svg')"
// :iconTrailing="require('@/assets/icons/pdf_icon.svg')"
// size="small"
// fontStyle="font-family name" // default is Roboto
// fontWeight="500" // default is 500
// dataId="test_data_id"
// />
// >
import styled from 'vue-styled-components'
import { mapGetters } from 'vuex'

const buttonProps = {
  backgroundColor: String,
  type: String,
  minWidth: String,
  maxWidth: String,
  fontSize: String,
  hasIcon: Boolean,
  marginTop: String,
  size: String,
  fontStyle: String,
  fontWeight: String
}
const leadingIcon = styled.img`
  margin-right: 16px;
  max-height: 30px;
  width: auto;
`
const trailingIcon = styled.img`
  margin-left: 16px;
  max-height: 30px;
  width: auto;
`
const ButtonContainer = styled('button', buttonProps)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: ${(props) =>
    props.type === 'white'
      ? `1px solid ${props.theme.colors.mediumGray}`
      : 'none'};
  outline: none;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.type === 'primary'
      ? props.theme.colors.primary
      : props.type === 'secondary'
      ? props.theme.colors.secondary
      : props.type === 'tertiary'
      ? props.theme.colors.tertiary
      : props.type === 'white'
      ? props.theme.colors.white
      : props.type === 'blue'
      ? props.theme.colors.blue
      : props.type === 'tertiary'
      ? props.theme.colors.white
      : props.type === 'black'
      ? props.theme.colors.black
      : props.theme.colors.blue};
  width: 100%;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '90px')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'none')};
  font-family: ${(props) => props.fontStyle};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) =>
    props.type === 'white'
      ? props.theme.colors.black
      : props.type === 'tertiary'
      ? props.theme.colors.white
      : props.type === 'black'
      ? props.theme.colors.white
      : props.theme.colors.white};
  padding: ${(props) =>
    props.hasIcon
      ? '10px 40px'
      : props.size === 'small'
      ? '7px 40px'
      : '16px 40px'};
  border-radius: ${(props) => props.theme.borderRadius};
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : props.type === 'primary' && props.theme.hasCustomPrimary
        ? props.theme.colors.primary
        : props.type === 'secondary'
        ? props.theme.colors.secondary
        : props.type === 'blue'
        ? '#3d8cb9'
        : props.type === 'white'
        ? props.theme.colors.lightGray
        : props.type === 'tertiary'
        ? props.theme.colors.mediumGray
        : props.type === 'black'
        ? '#404040'
        : '#3d8cb9'};
    opacity: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : props.type === 'primary' && props.theme.hasCustomPrimary
        ? '0.8'
        : props.type === 'secondary'
        ? '0.8'
        : '1'};
  }

  &:active {
    background-color: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : props.type === 'primary' && props.theme.hasCustomPrimary
        ? props.theme.colors.primary
        : props.type === 'secondary'
        ? props.theme.colors.secondary
        : props.type === 'blue'
        ? '#3A7698'
        : props.type === 'white'
        ? props.theme.colors.mediumGray
        : props.type === 'tertiary'
        ? props.theme.colors.lightGray
        : props.type === 'black'
        ? '#676767'
        : '#3A7698'};

    opacity: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : props.type === 'primary' && props.theme.hasCustomPrimary
        ? '0.5'
        : props.type === 'secondary'
        ? '0.5'
        : '1'};
  }

  :disabled {
    background-color: ${(props) => props.theme.colors.lightGray};
    cursor: not-allowed;
  }

  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  }
`

export default {
  name: 'button-component',
  components: {
    ButtonContainer,
    leadingIcon,
    trailingIcon
  },
  props: {
    backgroundColor: {
      required: false,
      type: String,
      default: null
    },
    type: {
      required: false,
      type: String,
      default: 'blue'
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    minWidth: {
      required: false,
      type: String,
      default: null
    },
    maxWidth: {
      required: false,
      type: String,
      default: null
    },
    text: {
      required: true,
      type: String,
      default: ''
    },
    fontSize: {
      required: false,
      type: String,
      default: null
    },
    iconLeading: {
      required: false,
      default: null
    },
    iconTrailing: {
      required: false,
      default: null
    },
    size: {
      required: false,
      default: 'medium' // small, medium, large
    },
    fontStyle: {
      required: false,
      default: "'Roboto', sans-serif"
    },
    fontWeight: {
      required: false,
      default: '500'
    },
    dataId: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      companySettings: 'getCompanySettings'
    }),
    computedFontStyle() {
      return this.companySettings.calculator_configuration
        .variation_point_definitions.styles.fontStyle
        ? this.companySettings.calculator_configuration
            .variation_point_definitions.styles.fontStyle
        : this.fontStyle
    },
    computedFontWeight() {
      return this.companySettings.calculator_configuration
        .variation_point_definitions.styles.buttonFontWeight
        ? this.companySettings.calculator_configuration
            .variation_point_definitions.styles.buttonFontWeight
        : this.fontWeight
    }
  }
}
</script>
