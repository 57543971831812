<template>
  <page-wrapper>
    <table-scroll>
      <table-container>
        <table-wrapper :data-id="dataId">
          <table-row>
            <table-header>{{ translate('component') }}</table-header>
            <table-header>{{ translate('quantity') }}</table-header>
            <table-header>{{ translate('unit_price') }}</table-header>
            <table-header>{{ translate('price') }}</table-header>
          </table-row>
          <table-row v-for="(item, index) in options" :key="index">
            <table-item>{{ item.name }}</table-item>
            <table-item
              >{{ item.quantity }}
              <span v-if="item.unit_short_name">{{
                item.unit_short_name
              }}</span></table-item
            >
            <table-item>{{ getValue(item.unit_price_cur) }}</table-item>
            <table-item>{{ getValue(item.total_price_cur) }}</table-item>
          </table-row>
          <table-row
            v-if="summaryInfo.vat_percentage && summaryInfo.total_vat_cur"
          >
            <table-item>{{ translate('vat') }}</table-item>
            <table-item bold
              >{{ getValue(summaryInfo.vat_percentage) }}%</table-item
            >
            <table-item />
            <table-item bold>{{
              getValue(summaryInfo.total_vat_cur)
            }}</table-item>
          </table-row>
          <table-row
            v-if="summaryInfo.price_total_vat_cur"
            :data-id="totalRowDataId"
          >
            <table-item>{{ translate('total_amount_VAT') }}</table-item>
            <table-item />
            <table-item />
            <table-item bold>{{
              getValue(summaryInfo.price_total_vat_cur)
            }}</table-item>
          </table-row>
        </table-wrapper>
      </table-container>
    </table-scroll>
  </page-wrapper>
</template>

<script>
import styled from 'vue-styled-components'
import { numberToString } from '@eturnity/eturnity_reusable_components/src/helpers/numberConverter'
import { mapGetters } from 'vuex'

const PageWrapper = styled.div`
  position: relative;
`

const TableScroll = styled.div`
  position: relative;
  max-width: 100%;
  overflow: hidden;
`

const TableContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: auto;
  overflow-x: auto;
  margin-bottom: 70px;
`

const TableWrapper = styled.table`
  border-spacing: 0;
  width: 100%;
  border: none;
`

const TableRow = styled.tr`
  font-size: 14px;

  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.colors.mediumGray};
  }
`

const TableHeader = styled.th`
  white-space: nowrap;
  text-align: right;
  font-weight: 500;
  padding: 12px 10px;
  border-top: 1px solid ${(props) => props.theme.colors.mediumGray};
  border-right: 1px solid ${(props) => props.theme.colors.mediumGray};

  &:first-child {
    text-align: left;
  }

  &:last-child {
    border-right: none;
  }
`

const tableAttrs = { bold: Boolean }
const TableItem = styled('td', tableAttrs)`
  white-space: nowrap;
  text-align: right;
  padding: 12px 10px;
  border-top: 1px solid ${(props) => props.theme.colors.mediumGray};
  border-right: 1px solid ${(props) => props.theme.colors.mediumGray};
  font-weight: ${(props) => (props.bold ? '500' : 'inherit')};

  &:first-child {
    text-align: left;
    font-weight: 500;
  }

  &:last-child {
    border-right: none;
  }
`

export default {
  name: 'table-component',
  components: {
    TableContainer,
    TableWrapper,
    TableRow,
    TableHeader,
    TableItem,
    TableScroll,
    PageWrapper
  },
  props: {
    options: {
      required: true
    },
    dataId: {
      type: String,
      required: false,
      default: ''
    },
    totalRowDataId: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    getValue(val) {
      return numberToString({ value: val, numberPrecision: 2 })
    }
  },
  computed: {
    ...mapGetters({
      summaryInfo: 'getSummaryInfo'
    })
  }
}
</script>
