<template>
  <container>
    <input-wrapper>
      <input-container
        :hasUnit="!!question.unit_short_name"
        :placeholder="placeholder"
        :isError="isError"
        :inputWidth="inputWidth"
        :fontStyle="computedFontStyle"
        :value="textInput"
        :hasLength="!!textInput.length"
        :data-id="dataId"
        @input="onChangeHandler"
        @blur="onInputBlur()"
        @keyup.enter="$emit('on-enter-click')"
      />
      <unit-container
        v-if="question.unit_short_name"
        :hasLength="!!textInput.length"
        :isError="isError"
        >{{ question.unit_short_name }}</unit-container
      >
    </input-wrapper>
    <error-message v-if="isError">{{
      translate('enter_value_between')
        .replace(/%s/, minValue)
        .replace(/%s/, maxValue)
    }}</error-message>
  </container>
</template>

<script>
//This component should be used for questions with input fields only
//How to use:
// <input-number
//     placeholder="Enter distance"
//     :isError="false" //default is false
//     inputWidth="150px" //by default, this is 100%
//     :question="question"
//     fontStyle="font-family name" // default is Roboto
//     dataId="test_data_id"
//     :value="inputValue" //required
//     @input-change="onInputChange($event)" //required
//     @on-enter-click="onInputSubmit()"
// />
import styled from 'vue-styled-components'
import { mapGetters } from 'vuex'
import {
  stringToNumber,
  numberToString
} from '@eturnity/eturnity_reusable_components/src/helpers/numberConverter'

const Container = styled.div`
  width: 100%;
  position: relative;
`

const inputProps = {
  isError: Boolean,
  hasUnit: Boolean,
  inputWidth: String,
  fontStyle: String,
  hasLength: Boolean
}
const InputContainer = styled('input', inputProps)`
  border: 1px solid
    ${(props) =>
      props.isError
        ? props.theme.colors.red
        : props.hasLength
        ? props.theme.colors.primary
        : props.theme.colors.mediumGray};
  padding: ${(props) =>
    props.hasUnit ? '11px 40px 11px 10px' : '11px 5px 11px 10px'};
  border-radius: 4px;
  font-family: ${(props) => props.fontStyle};
  font-size: 16px;
  color: ${(props) =>
    props.isError ? props.theme.colors.red : props.theme.colors.primary};
  width: ${(props) => (props.inputWidth ? props.inputWidth : 'auto')};

  &::placeholder {
    color: ${(props) =>
      props.isError ? props.theme.colors.red : props.theme.colors.darkGray};
  }

  &:focus {
    outline: none;
  }
`

const InputWrapper = styled.span`
  position: relative;
`

const UnitContainer = styled('span', inputProps)`
  border-left: 1px solid
    ${(props) =>
      props.isError
        ? props.theme.colors.red
        : props.hasLength
        ? props.theme.colors.primary
        : props.theme.colors.mediumGray};
  position: absolute;
  right: 10px;
  top: 0;
  padding-left: 10px;
  color: ${(props) =>
    props.isError
      ? props.theme.colors.red
      : props.hasLength
      ? props.theme.colors.primary
      : props.theme.colors.mediumGray};
`

const ErrorMessage = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.red};
  padding-top: 16px;
`

export default {
  name: 'input-number',
  components: {
    Container,
    InputContainer,
    InputWrapper,
    UnitContainer,
    ErrorMessage
  },
  data() {
    return {
      textInput: '',
      numberPrecision: 0, // we set this on created. By default is 0
      minValue: 0,
      maxValue: 100
    }
  },
  props: {
    placeholder: {
      required: false,
      default: ''
    },
    isError: {
      required: false,
      default: false
    },
    question: {
      required: true
    },
    inputWidth: {
      required: false,
      default: null
    },
    fontStyle: {
      required: false,
      default: "'Roboto', sans-serif"
    },
    value: {
      required: true,
      default: null
    },
    clearInput: {
      required: false,
      default: false
    },
    dataId: {
      type: String,
      default: ''
    }
  },
  methods: {
    onChangeHandler($event) {
      this.textInput = $event
      let formattedValue = stringToNumber({
        value: $event,
        numberPrecision: this.numberPrecision
      })
      if (isNaN(formattedValue)) {
        this.textInput = ''
        formattedValue = ''
      }
      this.$emit('input-change', formattedValue)
    },
    onInputBlur() {
      let num = stringToNumber({
        value: this.textInput,
        numberPrecision: this.numberPrecision
      })
      this.textInput = numberToString({
        value: num,
        numberPrecision: this.numberPrecision
      })
    }
  },
  computed: {
    ...mapGetters({
      companySettings: 'getCompanySettings'
    }),
    computedFontStyle() {
      return this.companySettings.calculator_configuration
        .variation_point_definitions.styles.fontStyle
        ? this.companySettings.calculator_configuration
            .variation_point_definitions.styles.fontStyle
        : this.fontStyle
    }
  },
  created() {
    this.numberPrecision = this.question.number_format_precision
    this.minValue = this.question.number_min_allowed
    this.maxValue = this.question.number_max_allowed
    if (this.value) {
      this.textInput = numberToString({
        value: this.value,
        numberPrecision: this.numberPrecision
      })
    }
  },
  watch: {
    clearInput: function (value) {
      if (value) {
        // If the value is typed, then we should clear the textInput on Continue
        this.textInput = ''
      }
    },
    question: function (value) {
      this.numberPrecision = value.number_format_precision
      this.minValue = value.number_min_allowed
      this.maxValue = value.number_max_allowed
    }
  }
}
</script>
