<template>
  <container>
    <input-wrapper>
      <input-container
        :placeholder="placeholder"
        :isError="isError"
        :rows="rowHeight"
        :fontStyle="computedFontStyle"
        :value="value"
        :data-id="dataId"
        @input="onChangeHandler"
      />
    </input-wrapper>
    <error-message v-if="isError">{{
      translate('field_required')
    }}</error-message>
  </container>
</template>

<script>
// To use:
// <text-area-component
//     placeholder="Comments"
//     @input-change="onInputChange({ value: $event, type: 'note' })"
//     fontStyle="font-family name" // default is Roboto
//     :value="form.note"
//     rowHeight="4" //optional
//     :isError="false"
//     data-id="test_data_id"
// />
import styled from 'vue-styled-components'
import { mapGetters } from 'vuex'

const Container = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`

const inputProps = {
  isError: Boolean,
  fontStyle: String
}
const InputContainer = styled('textarea', inputProps)`
  border: ${(props) =>
    props.isError
      ? '1px solid ' + props.theme.colors.red
      : '1px solid ' + props.theme.colors.mediumGray};
  padding: ${(props) =>
    props.hasUnit ? '11px 40px 11px 10px' : '11px 5px 11px 10px'};
  border-radius: 4px;
  font-family: ${(props) => props.fontStyle};
  font-size: 16px;
  color: ${(props) => props.theme.colors.black};
  width: 100%;
  max-width: 100%;
  box-sizing: border-box; // to allow width of 100% with padding

  &::placeholder {
    color: ${(props) =>
      props.isError ? props.theme.colors.red : props.theme.colors.darkGray};
  }

  &:focus {
    outline: none;
  }
`

const InputWrapper = styled.span`
  position: relative;,
`

const ErrorMessage = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.red};
  position: absolute;
  bottom: -18px;
`

export default {
  name: 'text-area-component',
  components: {
    Container,
    InputContainer,
    InputWrapper,
    ErrorMessage
  },
  props: {
    placeholder: {
      required: false,
      default: ''
    },
    isError: {
      required: false,
      default: false
    },
    rowHeight: {
      required: false,
      default: '2'
    },
    fontStyle: {
      required: false,
      default: "'Roboto', sans-serif"
    },
    value: {
      required: true,
      default: null
    },
    dataId: {
      type: String,
      default: ''
    }
  },
  methods: {
    onChangeHandler($event) {
      this.$emit('input-change', $event)
    }
  },
  computed: {
    ...mapGetters({
      companySettings: 'getCompanySettings'
    }),
    computedFontStyle() {
      return this.companySettings.calculator_configuration
        .variation_point_definitions.styles.fontStyle
        ? this.companySettings.calculator_configuration
            .variation_point_definitions.styles.fontStyle
        : this.fontStyle
    }
  }
}
</script>
