import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './modules/authModule'
import questionModule from './modules/questionModule'
import notificationsModule from './modules/notificationsModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authModule,
    questionModule,
    notificationsModule
  }
})
