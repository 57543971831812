<template>
  <container>
    <flex-wrapper>
      <toggle-wrapper
        role="checkbox"
        :checked="isChecked"
        :data-id="dataId"
        tabindex="0"
        @click="onToggleChange"
        @keydown.space.prevent="onToggleChange"
      >
        <toggle-background />
        <toggle-dot :isChecked="isChecked" />
      </toggle-wrapper>
      <label-text v-html="label"></label-text>
    </flex-wrapper>
  </container>
</template>

<script>
// To use:
// <toggle
//   @on-toggle-change="onInputChange($event)"
//   :isChecked="toggleValue" // Boolean
//   label="My Label Text"
//   data-id="test_data_id"
// />

import styled from 'vue-styled-components'

const Container = styled.div`
  display: inline-block;
`

const FlexWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  align-items: center;
`

const LabelText = styled.div`
  color: ${(props) => props.theme.colors.darkGray};

  a {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
  }
`

const ToggleWrapper = styled.span`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 24px;
  border-radius: 9px;

  &:focus {
    outline: 0;
  }

  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    width: 80px;
    height: 40px;
  }
`

const ToggleBackground = styled.span`
  display: inline-block;
  border-radius: 100px;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.lightGray};
  transition: 0.4s ease;
`

const toggleProps = { isChecked: Boolean }
const ToggleDot = styled('span', toggleProps)`
  position: absolute;
  height: 14px;
  width: 14px;
  left: 5px;
  bottom: 5px;
  background-color: ${(props) =>
    props.isChecked
      ? props.theme.colors.primary
      : props.theme.colors.mediumGray};
  border-radius: 100%;
  transition: transform 0.4s ease;
  transform: ${(props) =>
    props.isChecked ? 'translateX(26px)' : 'translateX(0)'};

  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    height: 24px;
    width: 24px;
    transform: ${(props) =>
      props.isChecked ? 'translateX(45px)' : 'translateX(0)'};
    bottom: 8px;
  }
`

export default {
  name: 'toggle',
  components: {
    Container,
    ToggleBackground,
    ToggleDot,
    ToggleWrapper,
    FlexWrapper,
    LabelText
  },
  props: {
    label: {
      required: false,
      default: ''
    },
    isChecked: {
      required: true,
      default: false
    },
    dataId: {
      type: String,
      default: ''
    }
  },
  methods: {
    onToggleChange() {
      this.$emit('on-toggle-change', !this.isChecked)
    }
  }
}
</script>
