<template>
  <page-container :showScroll="hasOverflow" ref="pageRef">
    <options-container :responsive="responsive">
      <box-container
        v-for="(item, index) in options"
        :key="index"
        @click="onClickHandler({ id: item.activity_id })"
        :clickable="clickable"
        :data-id="`container_answered_question_item_${index + 1}`"
      >
        <question-number
          :data-id="`text_answered_question_item_${index + 1}_question_number`"
        >
          {{ translate('question') }} {{ index + 1 }}
        </question-number>
        <question-title
          :data-id="`text_answered_question_item_${index + 1}_question_title`"
        >
          {{ item.caption }}
        </question-title>
        <answer-text
          v-if="!item.answered_questions"
          :data-id="`text_answered_question_item_${index + 1}_answer`"
        >
          {{ item.answer.value }}
          <span v-if="item.answer.unit_short_name">{{
            item.answer.unit_short_name
          }}</span>
        </answer-text>
        <multi-container v-else>
          <template v-for="(selection, idx) in item.answered_questions">
            <answer-text
              :key="idx"
              :data-id="`text_answered_question_item_${
                index + 1
              }_multi_question_${idx + 1}`"
            >
              {{ selection.caption }}:
            </answer-text>
            <answer-text
              :key="idx + 0.1"
              :data-id="`text_answered_question_item_${
                index + 1
              }_multi_question_${idx + 1}_answer`"
            >
              {{ selection.answer.value }}
              <span v-if="selection.answer.unit_short_name">{{
                selection.answer.unit_short_name
              }}</span></answer-text
            >
          </template>
        </multi-container>
      </box-container>
    </options-container>
  </page-container>
</template>

<script>
// To use:
// <selected-answers
//   :options="selectedAnswers"
//   @on-question-click="onQuestionClick($event)"
//   :responsive="true" // whether to allow more than one item per row
// />
import styled from 'vue-styled-components'
import { mapActions, mapGetters } from 'vuex'

const pageAttrs = { showScroll: Boolean }
const PageContainer = styled('div', pageAttrs)`
  max-height: 100%;
  width: 100%;
  overflow: auto;
  direction: rtl; // to get the scrollbar to the left

  ::-webkit-scrollbar {
    width: ${(props) =>
      props.showScroll ? '10px' : '0'}; //width of the whole scrollbar area
  }

  track ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    border-left: 2px solid ${(props) => props.theme.colors.mediumGray}; // width of the actual scrollbar
  }

  @media (max-width: ${(props) => props.theme.screen.mobileLarge}) {
    margin-bottom: 50px;
  }
`

const MultiContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 32px;
  grid-row-gap: 10px;
`

const optionsAttrs = { responsive: Boolean }
const OptionsContainer = styled('div', optionsAttrs)`
  display: grid;
  grid-template-columns: ${(props) =>
    props.responsive ? 'repeat(auto-fill, minmax(300px, 1fr))' : '1fr'};
  grid-gap: 4px;
  max-height: 100%;
  width: 100%;
  direction: ltr;
  padding-bottom: 40px;
`

const boxAttrs = { clickable: Boolean }
const BoxContainer = styled('div', boxAttrs)`
  border: 1px solid ${(props) => props.theme.colors.mediumGray};
  border-radius: 4px;
  padding: 10px;
  text-align: left;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};
`

const QuestionNumber = styled.div`
  color: ${(props) => props.theme.colors.mediumGray};
  font-size: 12px;
  margin-bottom: 6px;
`

const QuestionTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;

  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    font-size: 18px;
  }
`

const AnswerText = styled.div`
  font-size: 16px;

  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    font-size: 20px;
  }
`

export default {
  name: 'selected-answers',
  components: {
    PageContainer,
    BoxContainer,
    QuestionNumber,
    QuestionTitle,
    AnswerText,
    OptionsContainer,
    MultiContainer
  },
  data() {
    return {
      hasOverflow: false
    }
  },
  props: {
    options: {
      required: true,
      default: []
    },
    responsive: {
      required: false,
      default: false
    },
    clickable: {
      required: false,
      default: true
    },
    maxHeight: {
      required: false,
      default: null
    }
  },
  methods: {
    ...mapActions({
      modifyAnswer: 'postModifyAnswer'
    }),
    onClickHandler({ id }) {
      // If the session is over, prevent the answer from being clicked
      // This is due to BE not being able to handle this at the moment
      if (this.sessionStatus === 'completed') {
        return
      }
      const data = {
        activity_id: id
      }
      this.modifyAnswer({ data })
    }
  },
  computed: {
    ...mapGetters({
      sessionStatus: 'getSessionStatus'
    })
  },
  watch: {
    options: function () {
      setTimeout(() => {
        // We need this timeout to wait until the DOM is finished loading the new data so that
        // we can get the height to know if we should show the scrollbar
        const wrapper = this.$refs.pageRef
        this.hasOverflow = this.maxHeight
          ? wrapper.$el.scrollHeight > this.maxHeight
          : false
      }, 100)
    }
  }
}
</script>
