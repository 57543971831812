const theme = {
  colors: {
    primary: '#48a2d0',
    secondary: '#fdb813',
    tertiary: '#d5d5d5',
    black: '#353535',
    yellow: '#fdb813',
    darkGray: '#818181',
    mediumGray: '#d5d5d5',
    lightGray: '#f2f2f2',
    white: '#fff',
    blue: '#48a2d0',
    red: '#ff7e7e'
  },
  hasCustomPrimary: false, // need this to know for the button hovers
  screen: {
    mobileSmall: '345px',
    mobile: '425px',
    mobileLarge: '530px',
    tablet: '768px',
    tabletLarge: '950px'
  },
  borderRadius: '4px'
}

export default theme
