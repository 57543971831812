<template>
  <mobile-container v-if="$screen.width < 951">
    <spinner :fullWidth="true" v-if="pdfRequesting" />
    <page-one v-if="currentPage === 1">
      <question-title :questionText="translate('summary_almost_there')" />
      <!-- <question-subtitle
        >Tap on a question to edit your answer</question-subtitle
      > -->
      <selected-answers
        :options="answeredQuestions"
        :clickable="false"
        responsive
      />
      <mobile-button-container>
        <button-mobile
          :text="translate('reset_calculator')"
          @click.native="resetCalculator"
          :isDisabled="false"
        />
        <button-mobile
          :forwardArrow="true"
          :text="translate('continue')"
          :isDisabled="false"
          :isValid="true"
          @click.native="onNextPageClick()"
        />
      </mobile-button-container>
    </page-one>
    <page-two
      v-else
      :textOnly="
        !summaryInfo.main_components.length && !summaryInfo.cost_lines.length
      "
    >
      <question-title :questionText="summaryInfo.summary_page_text" />
      <chosen-component
        :options="summaryInfo.main_components"
        v-if="summaryInfo.main_components.length"
        @on-click-image="onImageClick($event)"
        :selectedImage="selectedImage"
      />
      <mobile-large-image v-if="selectedImage !== null">
        <large-image
          :src="summaryInfo.main_components[selectedImage].img_url"
        />
        <close-button @click.native="onImageClick(null)" class="close" />
      </mobile-large-image>
      <amount-wrapper v-if="summaryInfo.price_total_vat_cur">
        <question-title
          noMargin
          :questionText="translate('total_amount_VAT') + ':'"
        />
        <total-amount>{{ getTotalAmount }} {{ getCurrency }}</total-amount>
      </amount-wrapper>
      <table-component
        v-if="showDetailedTable"
        :options="summaryInfo.cost_lines"
      />
      <mobile-summary-buttons>
        <button-component
          :disabled="false"
          type="primary"
          :text="translate('get_pdf_short')"
          :iconLeading="require('@/assets/icons/pdf_icon.svg')"
          @click.native="toggleSummaryModal()"
          fontSize="14px"
        />
        <button-mobile
          :text="translate('reset_calculator')"
          @click.native="resetCalculator"
          :isDisabled="false"
          :backArrow="true"
          v-show="showMobileReset"
        />
      </mobile-summary-buttons>
      <summary-request
        :isOpen="showPdfSummary"
        @on-close-summary="toggleSummaryModal()"
        @on-summary-submitted="onSummarySubmit()"
      />
      <modal
        :isOpen="showSuccessModal"
        @on-close="onToggleModal('showSuccessModal')"
      >
        <modal-container>
          <modal-title>{{ translate('thank_you') }}</modal-title>
          <modal-body>{{ translate('pdf_request_submitted_body') }}</modal-body>
        </modal-container>
      </modal>
      <modal
        :isOpen="showSubmittedModal"
        @on-close="onToggleModal('showSubmittedModal')"
      >
        <modal-container>
          <modal-title>{{
            translate('pdf_request_received_title')
          }}</modal-title>
          <modal-body>{{ translate('pdf_request_received_body') }}</modal-body>
        </modal-container>
      </modal>
    </page-two>
  </mobile-container>
  <page-container v-else :isEmbedded="getIsEmbedded">
    <spinner :fullWidth="true" v-if="pdfRequesting" />
    <left-container>
      <large-image-container v-if="selectedImage !== null">
        <large-image
          :src="summaryInfo.main_components[selectedImage].img_url"
        />
        <close-button @click.native="onImageClick(null)" class="close" />
      </large-image-container>
      <selected-answers
        :options="answeredQuestions"
        v-if="selectedImage === null"
        :clickable="false"
      />
      <button-container>
        <button-component
          v-if="selectedImage === null"
          :disabled="false"
          type="white"
          dataId="button_summary_page_reset_configurator"
          :text="translate('reset_calculator')"
          @click.native="resetCalculator"
        />
      </button-container>
    </left-container>
    <right-container>
      <question-title
        dataId="text_summary_page_finished"
        :questionText="summaryInfo.summary_page_text"
      />
      <chosen-component
        :options="summaryInfo.main_components"
        v-if="summaryInfo.main_components.length"
        @on-click-image="onImageClick($event)"
        :selectedImage="selectedImage"
      />
      <amount-wrapper v-if="summaryInfo.price_total_vat_cur">
        <question-title
          noMargin
          dataId="text_summary_page_total_sales_tax"
          :questionText="translate('total_amount_VAT') + ':'"
        />
        <total-amount data-id="text_summary_page_total_sales_tax_value">
          {{ getTotalAmount }} {{ getCurrency }}
        </total-amount>
      </amount-wrapper>
      <table-component
        v-if="showDetailedTable"
        dataId="table_summary_page_components"
        totalRowDataId="table_row_summary_page_total_sales_tax"
        :options="summaryInfo.cost_lines"
      />
      <button-component
        :disabled="false"
        type="primary"
        dataId="button_summary_page_get_a_summary_as_pdf"
        :text="translate('get_pdf_long')"
        :iconLeading="require('@/assets/icons/pdf_icon.svg')"
        @click.native="toggleSummaryModal()"
      />
    </right-container>
    <summary-request
      :isOpen="showPdfSummary"
      @on-close-summary="toggleSummaryModal()"
      @on-summary-submitted="onSummarySubmit()"
    />
    <modal
      :isOpen="showSuccessModal"
      @on-close="onToggleModal('showSuccessModal')"
    >
      <modal-container>
        <modal-title data-id="text_thank_you_modal_title">
          {{ translate('thank_you') }}
        </modal-title>
        <modal-body
          v-html="translate('pdf_request_submitted_body')"
          data-id="text_thank_you_modal_body"
        />
      </modal-container>
    </modal>
    <modal
      :isOpen="showSubmittedModal"
      @on-close="onToggleModal('showSubmittedModal')"
    >
      <modal-container>
        <modal-title>{{ translate('pdf_request_received_title') }}</modal-title>
        <modal-body>{{ translate('pdf_request_received_body') }}</modal-body>
      </modal-container>
    </modal>
  </page-container>
</template>

<script>
import styled from 'vue-styled-components'
import { mapGetters, mapActions } from 'vuex'
import SelectedAnswers from '@/components/reusable-components/SelectedAnswers'
import ChosenComponent from '@/components/reusable-components/ChosenComponent'
import QuestionTitle from '@/components/reusable-components/QuestionTitle'
import ButtonComponent from '@/components/reusable-components/ButtonComponent'
import CloseButton from '@/components/reusable-components/CloseButton'
import TableComponent from '@/components/reusable-components/TableComponent'
import ButtonMobile from '@/components/reusable-components/ButtonMobile'
import SummaryRequest from '@/components/SummaryRequest'
import Spinner from '@/components/reusable-components/Spinner'
import Modal from '@/components/reusable-components/Modal'
import { numberToString } from '@eturnity/eturnity_reusable_components/src/helpers/numberConverter'

const MobileContainer = styled.div``

const MobileSummaryButtons = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 12px 0 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  align-items: center;
  justify-items: center;
  width: 100%;
  background: white;

  button {
    width: 95%;
  }
`

const MobileButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: calc(100% - 80px);
  padding: 12px 0;
  background: white;
  z-index: 1;

  div:nth-child(1) {
    justify-self: flex-start;
  }

  div:nth-child(2) {
    justify-self: flex-end;
    padding-right: 16px;
  }

  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    width: calc(100% - 32px);
  }

  @media (max-width: ${(props) => props.theme.screen.mobileSmall}) {
    width: 100%;
  }
`

const ModalContainer = styled.div`
  padding: 50px 30px 30px;
  min-width: 350px;

  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    padding: 0 30px 30px;
    min-width: auto;
  }
`

const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  display: block;
  margin-bottom: 28px;
  text-align: center;
`

const ModalBody = styled.div`
  font-size: 14px;
  display: block;
  color: ${(props) => props.theme.colors.darkGray};
  text-align: center;
`

const pageAttrs = { isEmbedded: Boolean, textOnly: Boolean }
const PageContainer = styled('div', pageAttrs)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 50px; // This should only be for Desktop
  margin-top: ${(props) =>
    props.isEmbedded ? '0' : '50px'}; // This should only be for Desktop
  grid-gap: 80px;
  margin-bottom: ${(props) => (props.buttonMargin ? '80px' : '0')};

  @media (max-width: ${(props) => props.theme.screen.tablet}) {
    grid-template-columns: 1fr;
    margin-top: 0;
    grid-gap: 30px;
    grid-template-rows: auto 1fr;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`

const LeftContainer = styled.div``

const RightContainer = styled.div``

const AmountWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 12px;
  align-items: center;
  margin: 26px 0;

  @media (max-width: ${(props) => props.theme.screen.mobile}) {
    grid-template-columns: 1fr;
  }
`

const TotalAmount = styled.div`
  font-size: 26px;
  color: ${(props) => props.theme.colors.primary};
`

const ButtonContainer = styled.div`
  display: inline-flex;
  padding-top: 16px;
`

const LargeImageContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.mediumGray};
  height: 450px;
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  position: relative;

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`

const MobileLargeImage = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-rows: 1fr auto;
  background: white;
  z-index: 99;
  overflow: auto;

  .close {
    padding: 12px 0;
  }
`

const LargeImage = styled.img`
  max-height: 450px;
  max-width: 450px;
`

const PageOne = styled.div`
  padding: 16px;
  margin-bottom: 40px;

  @media (max-width: ${(props) => props.theme.screen.mobileSmall}) {
    padding: 6px;
  }
`

const PageTwo = styled('div', pageAttrs)`
  padding: ${(props) => (props.textOnly ? '150px 16px 140px' : '0 16px 140px')};
  margin-bottom: 40px;
`

// const QuestionSubtitle = styled.div`
//   font-size: 20px;
//   margin-bottom: 30px;
// `

export default {
  name: 'summary-page',
  components: {
    PageContainer,
    LeftContainer,
    RightContainer,
    SelectedAnswers,
    ChosenComponent,
    QuestionTitle,
    AmountWrapper,
    TotalAmount,
    ButtonComponent,
    ButtonContainer,
    LargeImageContainer,
    LargeImage,
    CloseButton,
    TableComponent,
    MobileContainer,
    PageOne,
    MobileLargeImage,
    // QuestionSubtitle,
    MobileButtonContainer,
    ButtonMobile,
    PageTwo,
    MobileSummaryButtons,
    SummaryRequest,
    Spinner,
    Modal,
    ModalContainer,
    ModalTitle,
    ModalBody
  },
  data() {
    return {
      selectedImage: null,
      currentPage: 1,
      showMobileReset: true,
      showPdfSummary: false,
      showSuccessModal: false,
      showSubmittedModal: false
    }
  },
  computed: {
    ...mapGetters({
      summaryInfo: 'getSummaryInfo',
      pdfRequesting: 'getPdfRequestLoading',
      companySettings: 'getCompanySettings',
      answeredQuestions: 'getSelectedAnswers',
      getIsEmbedded: 'getIsEmbedded',
      getPdfRequestError: 'getPdfRequestError'
    }),
    getCurrency() {
      return localStorage.getItem('currency')
    },
    getTotalAmount() {
      return numberToString({
        value: this.summaryInfo.price_total_vat_cur,
        numberPrecision: 0
      })
    },
    showDetailedTable() {
      return (
        !!this.summaryInfo.cost_lines &&
        this.summaryInfo.cost_lines.length &&
        this.companySettings.calculator_configuration.summary_page_display ===
          'detailed'
      )
    }
  },
  methods: {
    ...mapActions({
      resetCalculator: 'resetCalculator'
    }),
    onImageClick(value) {
      if (this.selectedImage === value) {
        this.selectedImage = null
      } else {
        this.selectedImage = value
      }
    },
    onToggleModal(modal) {
      this[modal] = !this[modal]
    },
    onNextPageClick() {
      this.currentPage = 2
      window.scrollTo(0, 0)
    },
    handleScroll() {
      this.showMobileReset = window.top.scrollY === 0
    },
    closeOnEscape(e) {
      if (e.key == 'Escape') {
        this.toggleSummaryModal()
      }
    },
    onSummarySubmit() {
      this.showSuccessModal = true
      this.toggleSummaryModal()
    },
    toggleSummaryModal() {
      const isPdfSubmitted = sessionStorage.getItem('requestSubmitted')
      if (isPdfSubmitted === 'true' && !this.showPdfSummary) {
        this.showSubmittedModal = true
        return
      }
      this.showPdfSummary = !this.showPdfSummary
      if (this.showPdfSummary) {
        window.addEventListener('keydown', this.closeOnEscape)
        // Google Analytics tracking below
        let event = {
          name: 'from_final_tab',
          category: 'Consulting Request',
          action: 'Visited',
          description: 'Clicked on request button in final tab'
        }

        this.analyticsTracking({ event })
      } else {
        window.removeEventListener('keydown', this.closeOnEscape)
      }
    }
  },
  unmounted() {
    this.currentPage = 1
  },
  created() {
    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
